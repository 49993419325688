import React from "react";
import Paper from "@mui/material/Paper";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import {
  Button,
  Table,
  TableContainer,
  Typography,
  Dialog,
  DialogTitle,
  DialogActions,

} from "@mui/material";
import {  Form, Formik } from "formik";
import moment from "moment";
import { useDispatch } from "react-redux";
import { paymentLoggedStatus } from "../../features/projects/projectSlice";

const Root = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  [theme.breakpoints.up("md")]: {
    width: "800px",
  },
}));
const RefundContainer = styled(TableContainer)(({ theme }) => ({
  padding: "1rem",
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  [theme.breakpoints.up("md")]: {
    width: "475px",
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: 'bold',
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.gray,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
    backgroundColor: "#ddd",
  },
}));
const ProjectContractsTable = (props) => {
  const { data } = props;
  const [isPaymentLogged, setisPaymentLogged] = React.useState(false);
  const dispatch = useDispatch();
  const handlePaymentLoggedModal = () => {
    if (isPaymentLogged) {
      setisPaymentLogged(false);
    } else {
      setisPaymentLogged(true);
    }
  };


  return (
    <Root>
      <Paper sx={{ width: "100%", overflow: "hidden", mb: 3 }}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table" sx={{}}>
            <TableHead>
              <StyledTableRow>
                <StyledTableCell>
                  Transaction Type
                </StyledTableCell>
                <StyledTableCell>
                  Date
                </StyledTableCell>
                <StyledTableCell>
                  Paid To
                </StyledTableCell>
                <StyledTableCell>
                  Amount
                </StyledTableCell>
                <StyledTableCell>
                  Status
                </StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
            <TableRow>
            {data?.cardHolderName?.toLowerCase().includes('material') ? null : (
              <>
                <StyledTableCell>
                  {'Labor and Delivery Payment'}
                </StyledTableCell>
                <StyledTableCell>
                  {data?.paymentLogged ? moment(data?.paymentLoggedDate).format('MM/DD/YY') : '--'}
                </StyledTableCell>
                <StyledTableCell>
                {data?.paymentLogged ?  data?.name : '--'}
                
                </StyledTableCell>
                <StyledTableCell>
                  {(data?.totalAmount ? '$' + data?.totalAmount.toFixed(2) : '$0.00')}
                </StyledTableCell>

                <StyledTableCell>
                {data?.paymentLogged ? 'Paid' :  <Button variant="contained" onClick={handlePaymentLoggedModal}>
                    log payment
                  </Button>}

              
                  </StyledTableCell>
              </>
            )}
          </TableRow>

            </TableBody>
          </Table>
        </TableContainer>

      </Paper>
      {isPaymentLogged ? (
        <Dialog
          open={isPaymentLogged}
          scroll="body"
          onClose={handlePaymentLoggedModal}
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle
            id="scroll-dialog-title"
            sx={{
              p: 1,
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "24px",
              lineHeight: "24px",
              letterSpacing: "0.18px",
              color: "#000000",
            }}
          >
               <Typography
              sx={{
                fontWeight: "600",
                fontSize: "18px",
                color: "#000000",
              }}
            >Are you sure you want to confirm that {data?.name} has been paid for the Labor and Delivery Payment?</Typography>
            
          </DialogTitle>
          <RefundContainer>
   
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
             
            </Paper>
{            console.log('Data :',data)}
            <Formik
              initialValues={''}
              onSubmit={(values, formikHelpers) => {
                // console.log('oajusad ',values)
                if (data)
                {
                  dispatch(paymentLoggedStatus(data?._id))
                  .then(() => {
                      window.location.reload();
                  })
                  .catch(error => {
                      console.error("Error rescheduling project:", error);
                  });

              
              
                }
              
              }}
              
            >
              {({errors, touched, isValid, dirty}) => (
                <Form>
                <DialogActions>
                <Button variant="outlined" onClick={handlePaymentLoggedModal}>
                  Cancel
                </Button>
                <Button type="submit" variant="contained">
                  Confirm
                </Button>
      </DialogActions>
                </Form>
              )}
            </Formik>
          </RefundContainer>
        </Dialog>
      ) : null}
    </Root>
  );
};

export default ProjectContractsTable;
