import { Box, Typography, IconButton, Button } from '@mui/material';
import React, { useMemo, useRef, useState } from 'react'
import BeardcrumNavigator from '../../components/BeardcrumNavigator'
import Sidebar from '../../components/Sidebar'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import { serviceProductList, serviceResponseClr, updatePriceColumn, uploadProductServiceCSV } from '../../features/services/serviceSlice';
import Loading from '../../components/Loading';
import CloseIcon from '@mui/icons-material/Close';
import Alert  from "@mui/material/Alert";
import Papa from 'papaparse';
import { toTitleCase} from './Util';
import ServiceTable from './ServiceTable';
import { unwrapResult } from '@reduxjs/toolkit';

const ServiceProducts = ({ columns }) => {
  
const param = useParams();
const dispatch = useDispatch();
const {isDrawerOpen} = useSelector((store) => store.login)
const {serviceDetail, isLoading, responseStatus, responseMsg, alert} = useSelector((store) => store.service)
const serviceName = localStorage.getItem('serviceName');
const importCsv = useRef();
const exportCsvLinkRef = useRef();
const [searchValue, setSearchValue] = useState('');
const [alertDialog, setAlertDialog] = React.useState(false);
const [isDataUploaded, setIsDataUploaded]  =  useState(false)
const [interiorDoorServices, setInteriorDoorServices] = useState([
  { id: '/services/door-hardware', name: 'DOOR HARDWARE' },
  { id: '/services/door-casing', name: 'DOOR CASING' }
]);
const [editingRow, setEditingRow] = useState(null);
const [editedPrices, setEditedPrices] = useState({}); 
const [exportData, setExportData] = useState([]);

const location = useLocation();
console.log('serviceDetailserviceDetail": ',serviceDetail)

const handlePriceEdit = (rowId, currentPrice) => {

  const priceWithoutDollar = typeof currentPrice === 'string' ? currentPrice.replace(/^\$/, '') : currentPrice.toString();
  setEditingRow(rowId);
  setEditedPrices({ ...editedPrices, [rowId]: priceWithoutDollar });
  
};
const handlePriceChange = (rowId, newValue) => {
  setEditedPrices({ ...editedPrices, [rowId]: newValue });
};
const mapHeaders = (data, headerMapping, columnsConfig) => {
  const columnIds = columnsConfig.map(col => col.id); 

  return data.map(item => {
    const mappedItem = {};
    columnIds.forEach(key => {
      if (item[key] !== undefined) { 
        mappedItem[headerMapping[key] || key] = item[key];
      }
    });
    return mappedItem;
  });
};
const createHeaderMapping = (data, columnsConfig) => {
  if (data.length === 0) return {};

  const keys = Object.keys(data[0]);
  const mapping = {};

  keys.forEach(key => {
    const column = columnsConfig.find(col => col.id === key);
    if (column) {
      mapping[key] = column.label;
    } else {
      mapping[key] = key;
    }
  });

  return mapping;
};
const prepareExportData = () => {
  const headerMapping = createHeaderMapping(filteredData, columns); 
  const modifiedData = mapHeaders(filteredData, headerMapping, columns);
  setExportData(modifiedData);
  
};
const handlePriceSave = (rowId) => {
  const currentPath = location.pathname; 

  dispatch(updatePriceColumn({ id: rowId, ppui: Number(editedPrices[rowId]).toFixed(2), currentPath: currentPath }))
  .then(unwrapResult)
  .then((updatedResult) => {

    console.log('Update successful', updatedResult);
    setEditingRow(null); 
    dispatch(serviceProductList(param.serviceid)); 
  })
  .catch((error) => {
  
    console.error('Update failed', error);
    
  });
};
const breadcrumbs = [
    <Typography key="3" color="text.primary" style={{
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '34px',
        lineHeight: '36px',
        color: '#000000',
        textTransform: 'capitalize'
    }}>
      
         {toTitleCase(serviceName)}
    </Typography>
];
const handleSearch = (searchedValue) => {
  setSearchValue(searchedValue?.toLowerCase())

}
const isMatchUrl = (url) => {
  return location.pathname.includes(url);
}
const handleImportCsv = () => {
  importCsv.current.click();
}

  const filteredData = useMemo(() => {
  
    return serviceDetail?.data?.filter(row => 
        columns.some(column => 
            row[column.id]?.toString().toLowerCase().includes(searchValue?.toLowerCase())
        )
    ) ?? [];
}, [serviceDetail, columns, searchValue]);

const handleUploadCsv = (e) => {
  const serviceId = param.serviceid;
  const file = e.target.files[0]; 
  if (file) {
    setIsDataUploaded(true); // Indicate upload is starting
    Papa.parse(file, {
      header: true,
      complete: function(results) {
        console.log("Parsed CSV Data:", results.data);
        dispatch(uploadProductServiceCSV({
          csvData: results.data, 
          service: serviceId,
          fileName: file.name,
        }))
        .then(unwrapResult) 
        .then(() => {
      
          dispatch(serviceProductList(serviceId));
        })
        .catch((error) => {
          console.error('Upload failed or data refresh failed', error);
        })
        .finally(() => {
          setIsDataUploaded(false); 
        });
      },
    });
  }
};


useEffect(() => {
  dispatch(serviceProductList(param.serviceid));
}, [dispatch, param.serviceid]);


if (isLoading || !serviceDetail || isDataUploaded) {
  return <Loading />;
}


  return (
    <div className="page-section">
      <Sidebar/>
      <Box className="page-content" sx={{width: isDrawerOpen ? `calc(100% - 240px)` : `calc(100% - 57px)`, overflow: 'hidden'}}>
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 3
        }}>
          <BeardcrumNavigator breadcrumbs={breadcrumbs ? breadcrumbs : "Beardcrums"}/>
          <Box component="div" sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '1rem'
          }}>
            
            {isMatchUrl('64332da1beedb46ff5a0fcaf') ? (
          <>
            {interiorDoorServices.map((service) => (
              <Link to={service.id} key={service.id}     onClick={() => {
                let serviceName = service.name.toLowerCase();
                if (serviceName.includes('hardware')) {
                  localStorage.setItem('serviceName', 'interior door hardware');
                } else if (serviceName.includes('casing')) {
                  localStorage.setItem('serviceName', 'interior door casing');
                } else {
                  localStorage.setItem('serviceName', service.name);
                }
              }}>
                <Button variant="contained" className="bc-btn-outline">
                  {service.name} 
                </Button>
                </Link>
              ))}
              </>
            ) : <></>}

          <CSVLink data={filteredData ? exportData : []} filename={`export-${serviceName}.csv`}  onClick={prepareExportData}   ref={exportCsvLinkRef}>
            <Button variant="outlined" className="bc-btn-outline" color="primary">Export csv</Button>
          </CSVLink>
            <Button variant="contained" className="bc-btn-outline" color="primary" onClick={handleImportCsv}>Import csv</Button>
            <input type="file" name="importCsv" id="importCsv" style={{display: 'none'}} accept='text/csv' ref={importCsv}  onChange={(e) => handleUploadCsv(e)} />
          </Box>
        </Box>
        <Box component="div" sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          alignItems: 'center',
          mb: 3,
        }}>
          <Box component="div">
              <Box sx={{
                background: '#FFFFFF',
                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                borderRadius: '33px',
                height: 50,
                display: 'flex',
                alignItems: 'center',
                maxWidth: '245px',
                border: '1px solid #ddd',
                overflow: 'hidden'
        }}>
            <SearchRoundedIcon sx={{
              width: '16%',
              marginLeft: '6px'
            }}/>
            <input type="text" value={searchValue} placeholder='Search' className='search-input' onChange={(e) =>  handleSearch(e.target.value)} />
        </Box>
          </Box>
        </Box>
        {
              alert ? (
                <Alert 
                  severity={responseStatus}
                  color={responseStatus} 
                  sx={{mb: 3, width: '100%'}}
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        dispatch(serviceResponseClr(false));
                        setAlertDialog(false)
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >{responseMsg}</Alert>
              ) : null
            }
    
          {serviceDetail?.data ? (
            <ServiceTable
                data={filteredData}
                columns={columns}
                searchValue={searchValue}
                editingRow={editingRow}
                editedPrices={editedPrices}
                onEditPrice={handlePriceEdit}
                onPriceChange={handlePriceChange}
                onSavePrice={handlePriceSave}
                priceFieldName={  location.pathname.includes('64332da1beedb46ff5a0fcaf') ? 'rsPrice' : 'ppui'}

            />
            ) : <></>}
      </Box>
    </div>
  )
}

export default ServiceProducts