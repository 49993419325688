import {
  Box,
  Table,
  TableContainer,
  Typography,
  IconButton,
  Button,
} from "@mui/material";
import React, { useEffect } from "react";
import BeardcrumNavigator from "../../components/BeardcrumNavigator";
import Sidebar from "../../components/Sidebar";
import Paper from "@mui/material/Paper";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import {
  DataGrid,
  GridRowsProp,
  GridToolbar,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import { useDemoData } from "@mui/x-data-grid-generator";
import { useState } from "react";
import { Link } from "react-router-dom";
import Loading from "../../components/Loading";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { getCutomers } from "../../features/customer/customerSlice";
import { CSVLink } from "react-csv";
import FilterListIcon from "@mui/icons-material/FilterList";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

import NorthIcon from "@mui/icons-material/North";
import DynamicFilter from "../projects/DynamicFilter";

const Customers = () => {
  const { isDrawerOpen } = useSelector((store) => store.login);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });
  const [selectedFilters, setSelectedFilters] = useState({});
  const [filterModel, setFilterModel] = useState({
    items: [
      {
        field: "name",
        operator: "contains",
        value: "a",
      },
    ],
  });
  const requestSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const applyFilters = (customersData) => {
    const normalizedFilters = {};
    for (const [key, values] of Object.entries(selectedFilters)) {
      let normalizedKey = key.toLowerCase();
      if (normalizedKey === "phone") normalizedKey = "phoneNumber";
      normalizedFilters[normalizedKey] = values;
    }

    return customersData?.filter((customer) => {
      const fullName = (
        customer.firstName +
        " " +
        customer.lastName
      ).toLowerCase();
      const searchMatch =
        fullName.includes(searchValue.toLowerCase()) ||
        Object.values(customer).some((value) =>
          String(value).toLowerCase().includes(searchValue.toLowerCase())
        );

      if (!searchMatch) return false;

      return Object.entries(normalizedFilters).every(([key, values]) => {
        if (!values.size) return true;

        if (key === "name") {
          return Array.from(values).some((filterValue) =>
            fullName.includes(filterValue.toLowerCase())
          );
        }

        if (!customer.hasOwnProperty(key) || customer[key] == null)
          return false;

        return Array.from(values).some((filterValue) =>
          String(customer[key])
            .toLowerCase()
            .includes(filterValue.toLowerCase())
        );
      });
    });
  };

  // const VISIBLE_FIELDS = [
  //   "firstName",
  //   "email",
  //   "phoneNumber",
  //   "numberOfProperties",
  //   "numberOfOpenProjects",
  //   "numberOfCompletedProjects",
  //   "totalPurchases",
  //   "status",
  //   "lastLoginAt",
  // ];

  // const columns = [
  //   { id: 'firstName', label: 'Name', minWidth: 150 },
  //   { id: 'email', label: 'Email', minWidth: 150 },
  //   { id: 'phoneNumber', label: 'Phone', minWidth: 150 },
  //   { id: 'numberOfProperties', label: 'Number of Properties', minWidth: 100 },
  //   { id: 'numberOfOpenProjects', label: 'Open Projects', minWidth: 100 },
  //   { id: 'numberOfCompletedProjects', label: 'Completed Projects', minWidth: 100 },
  //   { id: 'totalPurchases', label: 'Total Purchases', minWidth: 100 },
  //   { id: 'status', label: 'Status', minWidth: 100 },
  //   { id: 'lastLoginAt', label: 'Last Active', minWidth: 250 }
  // ];

  const columns = [
    {
      headerName: "Name",
      field: "firstName",
      filterable: true,
      minWidth: 150,
      renderCell: (params) => (
        <Link
          to={`/customers/` + params.row.id}
          className="tableLink"
          style={{
            whiteSpace: "normal",
            wordBreak: "break-word",
            display: "inline-block",
          }}
        >
          {params.row.firstName + " " + params.row.lastName}
        </Link>
      ),
    },
    { headerName: "Email", field: "email", filterable: true, minWidth: 220 },

    {
      headerName: "Phone",
      field: "phoneNumber",
      filterable: true,
      minWidth: 150,
    },
    {
      field: "numberOfProperties",
      headerName: "Number of Properties",
      filterable: true,
      minWidth: 150,

      renderHeader: () => (
        <div
          style={{
            whiteSpace: "normal",
            wordBreak: "break-word",
            display: "inline-block",
          }}
        >
          Number of Properties
        </div>
      ),
    },
    {
      field: "numberOfOpenProjects",
      headerName: "Open Projects",
      filterable: true,
      minWidth: 130,
      renderHeader: () => (
        <div
          style={{
            whiteSpace: "normal",
            wordBreak: "break-word",
            display: "inline-block",
          }}
        >
          Open Projects
        </div>
      ),
    },
    {
      field: "numberOfCompletedProjects",
      headerName: "Completed Projects",
      filterable: true,
      minWidth: 160,

      renderHeader: () => (
        <div
          style={{
            whiteSpace: "normal",
            wordBreak: "break-word",
            display: "inline-block",
          }}
        >
          Completed Projects
        </div>
      ),
    },
    {
      field: "totalPurchases",
      headerName: "Total Purchases",
      filterable: true,
      minWidth: 200,

      renderCell: (params) => (
        <div style={{ display: "flex", alignItems: "center", height: "100%" }}>
          ${params.row.totalPurchases.toFixed(2)}
        </div>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      filterable: true,
      minWidth: 130,
      renderCell: (params) => (
        <div style={{ display: "flex", alignItems: "center", height: "100%" }}>
          {Boolean(params.row.statusBit) === true ? "Enabled" : "Disabled"}
        </div>
      ),
    },
    {
      field: "lastLoginAt",
      headerName: "Last Active",
      filterable: true,
      minWidth: 350,

      renderCell: (params) => (
        <div style={{ display: "flex", alignItems: "center", height: "100%" }}>
          {new Intl.DateTimeFormat("en-US", {
            timeZone: "America/Los_Angeles",
            year: "2-digit",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            hour12: true,
            timeZoneName: "short",
          })
            .format(new Date(params.row.lastLoginAt))
            .replace(/,\s/g, " ")
            .replace(/\s(AM|PM)/, "$1")
            .replace(/PST|PDT/g, "PT")}
        </div>
      ),
    },
  ];

  const rows = [
    { id: 1, col1: "Hello", col2: "World" },
    { id: 2, col1: "DataGridPro", col2: "is Awesome" },
    { id: 3, col1: "MUI", col2: "is Amazing" },
  ];
  const breadcrumbs = [
    <Typography
      key="3"
      color="text.primary"
      style={{
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "34px",
        lineHeight: "36px",
        color: "#000000",
      }}
    >
      Customers
    </Typography>,
  ];

  const [page, setPage] = React.useState(0);
  const { isLoading, customers } = useSelector((store) => store.customer);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchValue, setSearchValue] = useState("");
  const dispatch = useDispatch();

  const [sortedCustomers, setSortedCustomers] = useState([]);
  const [filterModalOpen, setFilterModalOpen] = useState(false);

  const name = [
    ...new Set(
      customers?.data?.map(
        (customer) => customer.firstName + " " + customer.lastName
      )
    ),
  ];
  const email = [
    ...new Set(customers?.data?.map((customer) => customer.email)),
  ];
  const phone = [
    ...new Set(customers?.data?.map((customer) => customer.phoneNumber)),
  ];

  const { data } = customers;
  console.log("Data: ", data);

  const customer_filters = {
    name,
    email,
    phone,
  };

  useEffect(() => {
    const filtered = applyFilters(customers.data);
    setSortedCustomers(filtered);
  }, [customers.data, selectedFilters, searchValue]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleSearch = (searchedValue) => {
    setSearchValue(searchedValue);
    setPage(0);
  };

  useEffect(() => {
    dispatch(getCutomers());
  }, [dispatch]);

  // const getExportableData = (data) => {
  //   return data.map((customer) => ({
  //     Name: customer.firstName + " " + customer.lastName,
  //     Email: customer.email,
  //     Phone: customer.phoneNumber,
  //     "Number of Properties": customer.numberOfProperties,
  //     "Open Projects": customer.numberOfOpenProjects,
  //     "Completed Projects": customer.numberOfCompletedProjects,
  //     "Total Purchases": customer.totalPurchases,
  //     Status: customer.status, // Ensure this matches your data structure
  //     "Last Active": new Intl.DateTimeFormat("en-US", {
  //       timeZone: "America/Los_Angeles",
  //       year: "2-digit",
  //       month: "2-digit",
  //       day: "2-digit",
  //       hour: "2-digit",
  //       minute: "2-digit",
  //       second: "2-digit",
  //       hour12: true,
  //       timeZoneName: "short",
  //     }).format(new Date(customer.lastLoginAt)),
  //   }));
  // };

  useEffect(() => {
    // Only update sortedCustomers if it's not already set and customers.data is available
    if (customers?.data && sortedCustomers?.length === 0) {
      setSortedCustomers(customers.data);
    }
  }, [customers?.data, sortedCustomers?.length, selectedFilters]);

  if (isLoading || !sortedCustomers) {
    return <Loading />;
  }

  return (
    <div className="page-section">
      <Sidebar />
      <Box
        className="page-content"
        sx={{
          width: isDrawerOpen ? `calc(100% - 240px)` : `calc(100% - 57px)`,
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 3,
          }}
        >
          <BeardcrumNavigator
            breadcrumbs={breadcrumbs ? breadcrumbs : "Beardcrums"}
          />
          <div>
            <Box
              component="div"
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              {/* <CSVLink data={getExportableData(sortedCustomers)}>
                <Button
                  variant="outlined"
                  className="bc-btn-outline"
                  color="primary"
                >
                  Export csv
                </Button>
              </CSVLink> */}
            </Box>
            <br />
            {/* To Filter  */}
            {/* <Box
              component="div"
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              <Button
                variant="outlined"
                className="bc-btn-outline"
                color="primary"
                onClick={() => applyFilters}
              >
                Filter
              </Button>
            </Box> */}
          </div>
        </Box>
        <Box
          component="div"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            alignItems: "center",
            mb: 3,
          }}
        >
          <Box component="div">
            {/* <SearchBox /> */}
            <Box
              sx={{
                background: "#FFFFFF",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                borderRadius: "33px",
                height: 50,
                display: "flex",
                alignItems: "center",
                maxWidth: "245px",
                border: "1px solid #ddd",
                overflow: "hidden",
              }}
            >
              <SearchRoundedIcon
                sx={{
                  width: "16%",
                  marginLeft: "6px",
                }}
              />
              <input
                type="text"
                value={searchValue}
                // placeholder="Search"
                className="search-input"
                onChange={(e) => handleSearch(e.target.value)}
              />
            </Box>
          </Box>
        </Box>

        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <Box
            sx={{
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "#e0e0e0",
                color: "black",
                display: "flex!important ",
                flexDirection: "row !important ",
              },
              "& .MuiDataGrid-columnHeader": {
                backgroundColor: "#e0e0e0",
                color: "black",
                display: "flex!important ",
                flexDirection: "row !important ",
              },

              "& .MuiDataGrid-row": {
                backgroundColor: "white",
              },
              "& .MuiDataGrid-row:nth-of-type(odd)": {
                backgroundColor: "#f5f5f5",
              },
            }}
          >
            <DataGrid
              autoHeight
              columns={columns}
              rows={sortedCustomers || []}
              rowHeight={70}
              slots={{ toolbar: GridToolbar }}
            />
            {/* 
          <TableContainer>
            <Table stickyHeader aria-label="sticky table" sx={{}}>
              <TableHead>
              <StyledTableRow>
                {columns.map((column) => (
                  <StyledTableCell key={column.id}>
                <Box
                  sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                  onClick={() => {column.id == "lastLoginAt" && requestSort(column.id)}}
                >
                  {column.label}
                  {column.id == "lastLoginAt" &&  ( 
                    <Box component="span" sx={{ display: 'inline-flex', alignItems: 'center', marginLeft: '5px' }}>
                      {sortConfig.key === column.id
                        ? (sortConfig.direction === 'ascending' ? <NorthIcon fontSize="small"/> : <ArrowDownwardIcon fontSize="small"/>)
                        : <ArrowDownwardIcon fontSize="small"/> 
                      }
                    </Box>
                  )}
                </Box>


              </StyledTableCell>

                ))}
              </StyledTableRow>
               
              </TableHead>
              <TableBody>
              {applyFiltersAndSorting(sortedCustomers)
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {            return(
                      <StyledTableRow key={row.id}>
                        <StyledTableCell sx={{minWidth: 150}}>
                          <Link to={`/customers/` + row.id} className='tableLink'>{row.firstName +' ' + row.lastName}</Link>
                        </StyledTableCell>
                        <StyledTableCell sx={{minWidth: 150}}>
                          <Typography >{row.email}</Typography>
                        </StyledTableCell>
                        <StyledTableCell sx={{minWidth: 150}}>
                          <Typography >{row.phoneNumber}</Typography>
                        </StyledTableCell>
                        <StyledTableCell sx={{minWidth: 150}}>
                          {row.numberOfProperties}
                        </StyledTableCell>
                        <StyledTableCell sx={{minWidth: 150}}>
                          {row.numberOfOpenProjects}
                        </StyledTableCell>
                        <StyledTableCell sx={{minWidth: 150}}>
                          {row.numberOfCompletedProjects}
                        </StyledTableCell>
                        <StyledTableCell sx={{minWidth: 150}}>
                          {'$'+row.totalPurchases.toFixed(2)}
                        </StyledTableCell>
                        <StyledTableCell sx={{minWidth: 150}}>
                          {(Boolean(row.statusBit) === true ) ? 'Enabled' : 'Disabled' }
                        </StyledTableCell>
                        <StyledTableCell sx={{ minWidth: 250, whiteSpace: 'nowrap' }}>
                  {new Intl.DateTimeFormat('en-US', {
                    timeZone: 'America/Los_Angeles',
                    year: '2-digit',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                    hour12: true,
                    timeZoneName: 'short'
                  }).format(new Date(row.lastLoginAt))
                    .replace(/,\s/g, ' ')
                    .replace(/\s(AM|PM)/, '$1')
                    .replace(/PST|PDT/g, 'PT')}
                </StyledTableCell>


                      </StyledTableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer> */}
            {/* <TablePagination
           
           

            component="div"
            count={sortedCustomers.length}  // This should be the count of currently displayed data
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}
          </Box>
        </Paper>
      </Box>
      {/* <DynamicFilter
        open={filterModalOpen}
        onClose={() => setFilterModalOpen(false)}
        filters={customer_filters}
        filterHeader={["Name", "Email", "Phone"]}
        applyFilters={applyFilters}
        onApply={handleApplyFilters} // Make sure this function is defined and passed here
      /> */}
    </div>
  );
};

export default Customers;
