import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
const url = 'https://course-api.com/react-useReducer-cart-project';

const initialState = {
    name: '',
    variables: {},
    isLoading: false,
};
export const getVariables = createAsyncThunk(
  'systemVariable/getVariables',
  async (thunkAPI) => {
    try {
      // console.log(name);
      // console.log(thunkAPI);
      // console.log(thunkAPI.getState());
      // thunkAPI.dispatch(openModal());
      const resp = await axios(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/admin/system-variables/detail`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        }
      });
    //   console.log(resp);
      return resp.data;
    } catch (error) {
    //   console.log(error.response);
      return thunkAPI.rejectWithValue('something went wrong');
    }
  }
);

function formatDecimal(value) {
  if (typeof value === 'number') {
    return value.toFixed(2);
  } else if (typeof value === 'string') {
    // Attempt to parse it as a number and format
    const num = parseFloat(value);
    return isNaN(num) ? value : num.toFixed(2);
  }
  return value;
}
export const updateVariable = createAsyncThunk(
  "systemVariable/updateVariable",
  async (values, thunkAPI) => {
    let id = thunkAPI.getState().systemVariable.variables.data._id;
    console.log(id, values);
    const formData = new FormData();
    
    formData.append("reliPortion", formatDecimal(values.reliPortion));
    formData.append("materialSurcharge", formatDecimal(values.materialSurcharge));
    formData.append("windowsPermitFee", formatDecimal(values.windowsPermitFee));
    formData.append("windowsDeliveryFee", formatDecimal(values.windowsDeliveryFee));
    formData.append("windowsReliFee", formatDecimal(values.windowsReliFee));
    formData.append("slidingGlassDoorPermitFee", formatDecimal(values.slidingGlassDoorPermitFee));
    formData.append("slidingGlassDoorDeliveryFee", formatDecimal(values.slidingGlassDoorDeliveryFee));
    formData.append("slidingGlassDoorReliFee", formatDecimal(values.slidingGlassDoorReliFee));
    formData.append("interiorDoorPermitFee", formatDecimal(values.interiorDoorPermitFee));
    formData.append("interiorDoorReliFee", formatDecimal(values.interiorDoorReliFee));
    formData.append("interiorDoorDeliveryFee", formatDecimal('30.00')); // Since '30.00' is already a string with two decimals, this step is technically not needed
    console.log('values in updateVariable', formData);
    try {

      const resp = await axios.post(
        `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/admin/system-variables/update/${id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      
      console.log(resp);
      return resp.data;
    } catch (error) {
      console.log(error.response);
      return thunkAPI.rejectWithValue('something went wrong');
    }
  }
);



const systemVariableSlice = createSlice({
  name: 'systemVariable',
  initialState,
  reducers: {
    clearCart: (state) => {
      state.cartItems = [];
    },
  },
  extraReducers: {
    [getVariables.pending]: (state) => {
      state.isLoading = true;
    },
    [getVariables.fulfilled]: (state, action) => {
      console.log(action.payload);
      state.isLoading = false;
      state.variables = action.payload;
    },
    [getVariables.rejected]: (state) => {
      state.isLoading = false;
    },
    [updateVariable.pending]: (state) => {
      state.isLoading = true;
    },
    [updateVariable.fulfilled]: (state, action) => {
      console.log(action.payload);
      state.isLoading = false;
    },
    [updateVariable.rejected]: (state) => {
      state.isLoading = false;
    },
  }
});


export const { clearCart } = systemVariableSlice.actions;

export default systemVariableSlice.reducer;
