const columnsConfig = {
    '6373e9f281b3c043a0225ffe': [
      { id: 'job_type', label: 'Job Type', minWidth: 100, fontWeight: '600', textTransform: 'capitalize' },
      { id: 'color', label: 'Color', minWidth: 100, fontWeight: '600', textTransform: 'capitalize' },
      { id: 'grid', label: 'Grid', minWidth: 150, fontWeight: '600', textTransform: 'capitalize' },
      { id: 'dimension_class', label: 'Dimension Class', minWidth: 100, fontWeight: '600', textTransform: 'capitalize' },
      { id: 'fit_type', label: 'Fit Type', minWidth: 100, fontWeight: '600', textTransform: 'capitalize' },
      { id: 'is_stack', label: 'Is Stack', minWidth: 100, fontWeight: '600', textTransform: 'capitalize' },
      { id: 'opening_type', label: 'Opening Type', minWidth: 150, fontWeight: '600', textTransform: 'capitalize' },
      { id: 'tempered_glass', label: 'Tempered Glass', minWidth: 100, fontWeight: '600', textTransform: 'capitalize' },
      { id: 'privacy', label: 'Privacy', minWidth: 100, fontWeight: '600', textTransform: 'capitalize' },
      { id: 'ppui', label: 'PPUI', minWidth: 100, fontWeight: '600', textTransform: 'capitalize' },
      { id: 'actions', label: 'Actions', minWidth: 100, fontWeight: '600' },
    ],
    '64332da1beedb46ff5a0fcaf': [
      { id: 'modelName', label: 'Model Name', minWidth: 100, fontWeight: '600', textTransform: 'capitalize' },
      { id: 'wallConditionJambWidth', label: 'Wall Condition Jamb Width', minWidth: 150, fontWeight: '600', textTransform: 'capitalize' },
      { id: 'jambWidthInches', label: 'Jamb Width (inches)', minWidth: 100, fontWeight: '600' },
      { id: 'doorWidth', label: 'Door Width', minWidth: 100, fontWeight: '600' },
      { id: 'doorWidthInches', label: 'Door Width (inches)', minWidth: 100, fontWeight: '600' },
      { id: 'doorHeight', label: 'Door Height', minWidth: 100, fontWeight: '600' },
      { id: 'doorHeightInches', label: 'Door Height (inches)', minWidth: 100, fontWeight: '600' },
      { id: 'unit', label: 'Unit', minWidth: 100, fontWeight: '600' },
      { id: 'overallFrameWidth', label: 'Overall Frame Width', minWidth: 100, fontWeight: '600' },
      { id: 'overallFrameHeight', label: 'Overall Frame Height', minWidth: 100, fontWeight: '600' },
      { id: 'surface', label: 'Surface', minWidth: 100, fontWeight: '600' },
      { id: 'thickness', label: 'Thickness', minWidth: 100, fontWeight: '600' },
      { id: 'doorThickness', label: 'Door Thickness', minWidth: 100, fontWeight: '600' },
      { id: 'coreType', label: 'Core Type', minWidth: 100, fontWeight: '600' },
      { id: 'doorThicknessInches', label: 'Door Thickness (inches)', minWidth: 100, fontWeight: '600' },
      { id: 'hinges', label: 'Hinges', minWidth: 100, fontWeight: '600' },
      { id: 'isFireRated', label: 'Is Fire Rated', minWidth: 100, fontWeight: '600' },
      { id: 'rsPrice', label: 'Rs Price', minWidth: 100, fontWeight: '600' },
      { id: 'actions', label: 'Actions', minWidth: 100, fontWeight: '600' },

    ],
    '64332d91beedb46ff5a0fcae': [
      { id: 'sizeType', label: 'Size Type', minWidth: 100, fontWeight: '600', textTransform: 'capitalize' },
      { id: 'jobType', label: 'Job Type', minWidth: 100, fontWeight: '600', textTransform: 'capitalize' },
      { id: 'color', label: 'Color', minWidth: 100, fontWeight: '600', textTransform: 'capitalize' },
      { id: 'widthGreaterThan', label: 'Width Greater Than', minWidth: 100, fontWeight: '600' },
      { id: 'widthLessThanEqualTo', label: 'Width Less Than or Equal To', minWidth: 150, fontWeight: '600' },
      { id: 'heightGreaterThan', label: 'Height Greater Than', minWidth: 100, fontWeight: '600' },
      { id: 'heightLessThanEqualTo', label: 'Height Less Than or Equal To', minWidth: 150, fontWeight: '600' },
      { id: 'panels', label: 'Panels', minWidth: 100, fontWeight: '600' },
      { id: 'type', label: 'Type', minWidth: 100, fontWeight: '600', textTransform: 'capitalize' },
      { id: 'glassType', label: 'Glass Type', minWidth: 100, fontWeight: '600', textTransform: 'capitalize' },
      { id: 'ppui', label: 'PPUI', minWidth: 100, fontWeight: '600' },
      { id: 'actions', label: 'Actions', minWidth: 100, fontWeight: '600' },

    ],
    'door-hardware': [
      { id: 'doorHardwareType', label: 'Door Hardware Type', minWidth: 100, fontWeight: '600', textTransform: 'capitalize' },
      { id: 'color', label: 'Color', minWidth: 100, fontWeight: '600', textTransform: 'capitalize' },
      { id: 'style', label: 'Style', minWidth: 100, fontWeight: '600', textTransform: 'capitalize' },
      { id: 'brand', label: 'Brand', minWidth: 100, fontWeight: '600', textTransform: 'capitalize' },
      { id: 'catalog', label: 'Catalog', minWidth: 100, fontWeight: '600', textTransform: 'capitalize' },
      { id: 'partNumber', label: 'Part Number', minWidth: 100, fontWeight: '600', textTransform: 'capitalize' },
      { id: 'rsPrice', label: 'RS Price', minWidth: 100, fontWeight: '600', textTransform: 'capitalize' },
  
    ],
    'door-casing': [
      { id: 'casingStyle', label: 'Casing Style', minWidth: 100, fontWeight: '600', textTransform: 'capitalize' },
      { id: 'doorTypeSupported', label: 'Door Type', minWidth: 100, fontWeight: '600', textTransform: 'capitalize' },
      { id: 'heightsAvailable', label: 'Heights Available', minWidth: 100, fontWeight: '600', textTransform: 'capitalize' },
      { id: 'casingSize', label: 'Casing Size', minWidth: 100, fontWeight: '600', textTransform: 'capitalize' },
      { id: 'reliPrice', label: 'RELI Price', minWidth: 100, fontWeight: '600', textTransform: 'capitalize' },
  
    ],
  };

  export default columnsConfig;
