import {
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  Typography,
  Dialog,
  DialogTitle,
  DialogActions,
  TextField,
} from "@mui/material";
import React, { useRef, useState } from "react";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "../../components/Sidebar";
import BeardcrumNavigator from "../../components/BeardcrumNavigator";
import OpenInNewRoundedIcon from "@mui/icons-material/OpenInNewRounded";
import ProjectContractsTable from "./ProjectContractsTable";
import ProjectCustomersTable from "./ProjectCustomersTable";
import ProjectAccountingBreakdown from "./ProjectAccountingBreakdown";
import { AboutCard } from "../../components/styles/ActivityBox.styles";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useEffect } from "react";
import {
  assignProjectToUser,
  cancelProject,
  deleteAssignStaff,
  getAssignedProject,
  getStaffForAssign,
  projectResponseClr,
  getCompaniesForAssign,
  rescheduleProject,
  singleProjectDetail,
  assignCompanyToOrder,
} from "../../features/projects/projectSlice";
import { Link, useParams } from "react-router-dom";
import Loading from "../../components/Loading";
import moment from "moment";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import Alert from "@mui/material/Alert";
import CloseIcon from "@mui/icons-material/Close";
import { Form, Formik } from "formik";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { object } from "yup";
import * as Yup from "yup";
import dayjs from "dayjs";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

import ActivityLog from "../../components/ActivityLog";
import { db } from "../../firebase";
import { collection, getDocs, query, where } from "firebase/firestore";

const OuterGrid = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  gap: "1rem",
}));
const WhiteCard = styled(Paper)(({ theme }) => ({
  background: "#F8F8F8",
  border: "1px solid #C4C4C4",
  borderRadius: "10px",
  padding: "20px",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  [theme.breakpoints.up("md")]: {
    flex: "1",
  },
}));
const WhiteCardHeading = styled("h3")(({ theme }) => ({
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "20px",
  lineHeight: "30px",
  color: "#000000",
  textTransform: "capitalize",
}));
const WarrantyCard = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  padding: "4px",
  gap: "8px",
  background: "#D9D9D9",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  [theme.breakpoints.up("md")]: {
    width: "360px",
  },
}));

const RescheduleContainer = styled(Box)(({ theme }) => ({
  padding: "3rem 4rem",
  [theme.breakpoints.down("lg")]: {
    width: "100%",
  },
  [theme.breakpoints.up("md")]: {
    width: "600px",
  },
}));

const ModalHeadingPopUp = styled(Typography)(({ theme }) => ({
  fontWeight: "400",
  fontSize: "34px",
  lineHeight: "36px",
  color: "#000000",
  marginBottom: ".7rem",
  width: "100%",
  textAlign: "center",
}));
const ReAssignBox = styled(Box)(({ theme }) => ({
  background: "#FFFFFF",
  height: "50px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "0 10px",
  border: ".6px solid black",
  borderRadius: "5px",
}));
const AssignBox = styled(Box)(({ theme }) => ({
  background: "#019EB2!important",
  height: "50px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "0 10px",
  border: "none",
}));
const ReAssignRemove = styled(Button)(({ theme }) => ({
  background: "#BA1A1A",
  borderRadius: "4px",
  height: "24px",
  width: "102px",
  color: "#FFFFFF",
  textTransform: "uppercase",
}));
const StatusBoxes = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  flexWrap: "wrap",
  alignItems: "center",
  background: "#FFFFFF",
  gap: "4px",
  padding: "8px 0",
  boxShadow:
    "0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)",
  flex: "146px 1",
}));

const ProjectDetails = () => {
  const { isDrawerOpen } = useSelector((store) => store.login);
  const {
    projectDetail,
    isLoading,
    responseStatus,
    alert,
    responseMsg,
    staffReassignedList,
    listofAssignStaff,
    listofCompanies,
  } = useSelector((store) => store.project);
  const [selectedValue, setSelectedValue] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const dispatch = useDispatch();
  const param = useParams();
  console.log(
    "projectDetail?.data?.order_info?.orderStatusDate: ",
    projectDetail?.data?.order_info?.orderStatusDate
  );
  const projectDate = projectDetail?.data?.order_info?.orderStatusDate
    ? dayjs(projectDetail.data.order_info.orderStatusDate)
    : dayjs();

  // Use "00:00" as the default time if null, otherwise use the provided time
  const projectTime = projectDetail?.data?.order_info?.orderStatusTime
    ? projectDetail.data.order_info.orderStatusTime
    : "00:00:00";

  const projectDateObj = dayjs(projectDate);
  const projectDateFormatted = projectDateObj.format("MM-DD-YYYY");
  const projectTimeFormatted = projectTime;
  console.log("projectDateFormatted: ", projectDateFormatted);

  console.log("projectDetail: ", projectDetail);
  const transactionSections = [
    { type: "Initial Payment", label: "Downpayment" },
    { type: "Verification & Prep Work", label: "Verification Payment" },
    { type: "Completion", label: "Completion Payment" },
    { type: "Contractor Payment", label: "Contractor Payment" },
    { type: "Refund", label: "Refund Payment" },
  ];
  const breadcrumbs = [
    <Typography
      key="3"
      color="text.primary"
      style={{
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "34px",
        lineHeight: "36px",
        color: "#000000",
      }}
    >
      <Link to={"/projects"} style={{ textDecoration: "none", color: "black" }}>
        <ArrowBackIosRoundedIcon
          style={{ marginRight: "1rem", cursor: "pointer" }}
        />
      </Link>{" "}
      {projectDetail.data?.order_detail?.length > 0 &&
      projectDetail.data?.order_detail[0].service
        ? projectDetail.data?.order_detail[0].service.name
        : ""}{" "}
      {projectDetail?.data?.order_info?.uniqueOrderID
        ? projectDetail?.data?.order_info?.uniqueOrderID
        : param.projectid}
    </Typography>,
  ];
  const doorDimensions = [
    {
      "Door Width": "3/0 (36 inch)",
      "Door Height": "6/8 (80 inches)",
      Unit: "Double Entry",
      "Overall Frame Width": 37.375,
      "Overall Frame Height": 81.25,
    },
    {
      "Door Width": "4/0 (48 inch)",
      "Door Height": "6/8 (80 inches)",
      Unit: "Double Entry",
      "Overall Frame Width": 49.375,
      "Overall Frame Height": 81.25,
    },
    {
      "Door Width": "4/4 (52 inch)",
      "Door Height": "6/8 (80 inches)",
      Unit: "Double Entry",
      "Overall Frame Width": 53.375,
      "Overall Frame Height": 81.25,
    },
    {
      "Door Width": "4/8 (56 inch)",
      "Door Height": "6/8 (80 inches)",
      Unit: "Double Entry",
      "Overall Frame Width": 57.375,
      "Overall Frame Height": 81.25,
    },
    {
      "Door Width": "5/0 (60 inch)",
      "Door Height": "6/8 (80 inches)",
      Unit: "Double Entry",
      "Overall Frame Width": 61.375,
      "Overall Frame Height": 81.25,
    },
    {
      "Door Width": "5/4 (64 inch)",
      "Door Height": "6/8 (80 inches)",
      Unit: "Double Entry",
      "Overall Frame Width": 65.375,
      "Overall Frame Height": 81.25,
    },
    {
      "Door Width": "5/8 (68 inch)",
      "Door Height": "6/8 (80 inches)",
      Unit: "Double Entry",
      "Overall Frame Width": 69.375,
      "Overall Frame Height": 81.25,
    },
    {
      "Door Width": "6/0 (72 inch)",
      "Door Height": "6/8 (80 inches)",
      Unit: "Double Entry",
      "Overall Frame Width": 73.375,
      "Overall Frame Height": 81.25,
    },
    {
      "Door Width": "4/0 (48 inch)",
      "Door Height": "8/0 (96 inches)",
      Unit: "Double Entry",
      "Overall Frame Width": 49.375,
      "Overall Frame Height": 97.25,
    },
    {
      "Door Width": "4/8 (56 inch)",
      "Door Height": "8/0 (96 inches)",
      Unit: "Double Entry",
      "Overall Frame Width": 57.375,
      "Overall Frame Height": 97.25,
    },
    {
      "Door Width": "5/0 (60 inch)",
      "Door Height": "8/0 (96 inches)",
      Unit: "Double Entry",
      "Overall Frame Width": 61.375,
      "Overall Frame Height": 97.25,
    },
    {
      "Door Width": "5/4 (64 inch)",
      "Door Height": "8/0 (96 inches)",
      Unit: "Double Entry",
      "Overall Frame Width": 65.375,
      "Overall Frame Height": 97.25,
    },
    {
      "Door Width": "6/0 (72 inch)",
      "Door Height": "8/0 (96 inches)",
      Unit: "Double Entry",
      "Overall Frame Width": 73.375,
      "Overall Frame Height": 97.25,
    },
  ];
  const [isReschedule, setIsReschedule] = useState(false);
  const [isReassign, setIsReassign] = useState(false);
  const [isAssign, setIsAssign] = useState(false);
  const [alertDialog, setAlertDialog] = React.useState(false);
  const [chatsData, setChatsData] = useState([]);
  const [isCompanySelected, setIsCompanySelected] = React.useState(false);
  const [selectedCompany, setSelectedCompany] = React.useState("");
  const selectRef = useRef();

  const handleRescheduleModal = () => {
    if (isReschedule) {
      setIsReschedule(false);
    } else {
      setIsReschedule(true);
    }
  };
  const handleReassignModal = () => {
    if (isReassign) {
      setIsReassign(false);
    } else {
      dispatch(getAssignedProject(param.projectid));
      dispatch(getStaffForAssign());
      setIsReassign(true);
    }
  };
  const handleAssignModal = () => {
    console.log("asdasdads");
    if (isAssign) {
      setIsAssign(false);
    } else {
      console.log("first");

      setIsAssign(true);
    }
  };
  const rescheduleInitialValues = {
    orderStatusDate: projectDateFormatted,
    // orderStatusTime: projectTimeFormatted,
  };
  const reassginInitialValues = {
    userTo: "",

    company: projectDetail.data?.assigned_order?.[0]?.company || "",
  };
  console.log(
    "projectDetail.data?.assigned_order: ",
    projectDetail.data?.assigned_order
  );

  const handleCancelProject = (e) => {
    let values = {};
    values.orderStatusDate = new Date();
    values.orderStatus = "Cancelled";
    values.orderStatusTime="Cancelled";
    dispatch(cancelProject(values));
  };

  const handleDeleteAssignStaff = (id) => {
    dispatch(deleteAssignStaff(id)).then(() => {
      handleReassignModal();
    });
  };

  const FetchChats = async (id) => {
    try {
      const querySnapshot = await getDocs(
        query(
          collection(db, "Chats-test"),
          where("MessageRoomDetails.ProjectID", "==", id)
        )
      );

      const chatsData = querySnapshot.docs.map(
        (doc) => doc.data().MessageRoomDetails
      );
      setChatsData(chatsData);
      console.log("chatsData: ", chatsData);
      // Update the state or perform any other actions with the filtered data
    } catch (error) {
      console.error("Error fetching chats:", error);
    }
  };

  useEffect(() => {
    dispatch(singleProjectDetail(param.projectid));
    dispatch(getAssignedProject(param.projectid));
    dispatch(getCompaniesForAssign());
    FetchChats(param.projectid);
  }, [param.projectid, dispatch, isDrawerOpen]);

  function renderAttributeBox(title, value) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          gap: "10px",
          borderTop: "0.8px solid #ddd",
          borderBottom: "0.8px solid #ddd",
          py: 1,
        }}
      >
        <Typography sx={{ fontSize: "15px", fontWeight: "bolder" }}>
          {title}
        </Typography>
        <Typography sx={{ fontSize: "15px" }}>{value}</Typography>
      </Box>
    );
  }

  function getOverallFrameWidth(service) {
    if (service?.doorWidth) {
      // Extracting the inches part from the Door Width and comparing it with service.doorWidth
      const matchingDimension = doorDimensions.find((dimension) => {
        const inches = dimension["Door Width"].match(/\((\d+) inch\)/);
        return inches && parseInt(inches[1]) === service.doorWidth;
      });

      // If a matching dimension is found, return its Overall Frame Width
      if (matchingDimension) {
        return matchingDimension["Overall Frame Width"];
      }
    }

    return null; // Return null if no match is found or service.doorWidth is not defined
  }
  function getOverallFrameHeight(service) {
    if (service?.doorHeight) {
      // Extracting the inches part from the Door Height and comparing it with service door height
      const matchingDimension = doorDimensions.find((dimension) => {
        const inches = dimension["Door Height"].match(/\((\d+) inch\)/);
        return inches && parseInt(inches[1]) === service.doorHeight;
      });

      // If a matching dimension is found, return its Overall Frame Height
      if (matchingDimension) {
        return matchingDimension["Overall Frame Height"];
      }
    }

    return null; // Return null if no match is found or service.doorHeight is not defined
  }
  function renderAttributes(service) {
    const overallFrameWidth = getOverallFrameWidth(service) || "";
    const overallFrameHeight = getOverallFrameHeight(service) || "";

    console.log("overallFrameWidthA:ASD ", overallFrameWidth);
    return (
      <>
        {service?.hardware && renderAttributeBox("Handing", service.hardware)}
        {service?.doorWidth &&
          renderAttributeBox("Door Width", service.doorWidth)}
        {service?.doorHeight &&
          renderAttributeBox("Door Height", service.doorHeight)}
        {renderAttributeBox("Overall Frame Width", overallFrameWidth)}
        {renderAttributeBox("Overall Frame Height", overallFrameHeight)}
        {renderAttributeBox("Surface Type", service.doorFinish)}
        {renderAttributeBox("Stiles", "F.J. Stiles")}
        {renderAttributeBox("Glass Type", "Not Applicable")}
        {service?.doorStyle &&
          renderAttributeBox("Model Name", service.doorStyle)}
        {renderAttributeBox(
          "Sticking",
          "Based on Model of Door Sticking Style"
        )}
        {service?.coreType &&
          renderAttributeBox("Thickness and Core", service.coreType)}
        {renderAttributeBox("Bore Prep", "Standard ABS Single")}
        {renderAttributeBox("Bevel", "Single Edge")}
        {renderAttributeBox("Interior Door Hardware Options", "No")}
        {renderAttributeBox("Prehanging Material", "Primed Pine")}
        {service?.jambWidthInches &&
          renderAttributeBox("Wall Condition", service.jambWidthInches)}
        {renderAttributeBox("Carpet Cut", "No")}
        {renderAttributeBox("Stop Type", '3/8" x 1 1/4" Stop')}
        {renderAttributeBox("Saw Kerf for Bullnose", "No")}
        {renderAttributeBox("Astragal", "Flat Astragal")}
        {renderAttributeBox("Flush Bolts", "Flush Bolt (Matches Hinges)")}
        {renderAttributeBox("Hinge Type", '1841 3 1/2" x 3 1/2" 5/8" Radius')}
        {service?.doorHingColor &&
          renderAttributeBox("Hinge Finish", service.doorHingColor)}
        {renderAttributeBox("Ball Bearing Hinges", "No")}
        {service?.doorHeight &&
          renderAttributeBox(
            "Number of Hinges Per Leaf",
            Number(service?.doorHeight) == 6 * 12 + 8 ? 3 : 4
          )}
        {renderAttributeBox("Request Unit Knocked Down", "No")}
        {renderAttributeBox("Label Door", "No")}
        {renderAttributeBox("Fire Rated", service.isFireRated ? "Yes" : "No")}
      </>
    );
  }

  const [selectionChanged, setSelectionChanged] = useState(false);

  function CustomDropdown({ listofCompanies, onSelectCompany, setFieldValue }) {
    const [dropdownTop, setDropdownTop] = useState(0);
    const toggleButtonRef = useRef(null);
    const dropdownRef = useRef(null);

    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    // Similar logic for handling right-clicks
    const handleRightClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    useEffect(() => {
      if (dropdownOpen) {
        document.addEventListener("mousedown", handleClickOutside);
        document.addEventListener("contextmenu", handleRightClickOutside);
      } else {
        document.removeEventListener("mousedown", handleClickOutside);
        document.removeEventListener("contextmenu", handleRightClickOutside);
      }

      return () => {
        // Cleanup the event listeners
        document.removeEventListener("mousedown", handleClickOutside);
        document.removeEventListener("contextmenu", handleRightClickOutside);
      };
    }, [dropdownOpen]);
    useEffect(() => {
      if (toggleButtonRef.current) {
        const height = toggleButtonRef.current.offsetHeight;
        setDropdownTop(height + 15);
      }
    }, []);
    const handleSelectCompany = (company) => {
      onSelectCompany(company);
      setFieldValue("company", company._id);
      setSelectedValue(`${company.companyName} - (${company.addressOne})`); // This will update the button label
      setDropdownOpen(false);
      setSelectionChanged(true);
    };

    return (
      <div
        ref={dropdownRef}
        style={{
          width: "100%",
          cursor: "pointer",
          position: "relative",

          fontSize: "16px",
          color: "#333",
          background: "transparent",
          fontFamily: "Arial, sans-serif",
        }}
      >
        <div
          ref={toggleButtonRef}
          style={{
            fontWeight: "bold",
            background: "#FFFFFF",
            width: "100%",
            padding: "1px 12px",
            boxSizing: "border-box",
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
            backgroundColor: "transparent",
            borderRadius: "1px",
          }}
          onClick={() => setDropdownOpen(!dropdownOpen)}
        >
          {selectedValue || "Select a Company"}
          <span>
            <ArrowDropDownIcon />
          </span>
        </div>
        {dropdownOpen && (
          <div
            style={{
              position: "absolute",
              top: "39px",
              left: "-10px",
              zIndex: 1000,
              scrollbarWidth: "none",
              overflowY: "auto",
              borderRadius: "8px",
              borderBottom: "0.5px solid #808080",
              //  borderTop:"1px solid #808080",
              maxHeight: "150px",
              width: "104.5%",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {listofCompanies.data.map((assignCompany) => {
              const { _id, companyName, addressOne } = assignCompany;
              return (
                <div
                  key={_id}
                  onClick={() => handleSelectCompany(assignCompany)}
                  style={{
                    padding: "8px 16px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    background: "#FFF",
                    borderRight: "1px solid #808080",
                    borderLeft: "1px solid #808080",
                    fontWeight: "normal",
                    width: "100%",
                    backgroundColor: "transparent",
                    whiteSpace: "nowrap", // Keeps text on one line
                    textOverflow: "ellipsis", // Adds ellipsis for overflowing text
                    overflow: "hidden", // Prevents text spill
                  }}
                >
                  <span
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: "40%",
                    }}
                  >
                    {companyName}
                  </span>
                  <span
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: "60%",
                    }}
                  >
                    {addressOne}
                  </span>
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  }

  function renderOtherAttrs(service) {
    const gridPrice = service?.gridPice ?? 0;
    const hasGrid = gridPrice !== 0;
    return (
      <>
        {service?.roomType
          ? renderAttributeBox("Room Type:", service?.roomType)
          : null}
        {service?.width
          ? renderAttributeBox("Door Width:", service?.width)
          : null}
        {service?.height
          ? renderAttributeBox("Door Height:", service?.height)
          : null}
        {service?.overallFrameWidth
          ? renderAttributeBox(
              "Overall Frame Width:",
              service?.overallFrameWidth
            )
          : null}
        {service?.overallFrameHeight
          ? renderAttributeBox(
              "Overall Frame Height:",
              service?.overallFrameHeight
            )
          : null}
        {service?.surfaceType
          ? renderAttributeBox("Surface Type:", service?.surfaceType)
          : null}
        {service?.stiles
          ? renderAttributeBox("Stiles:", service?.stiles)
          : null}
        {service?.glassType && service?.glassType !== "undefined"
          ? renderAttributeBox("Glass Type:", service?.glassType)
          : renderAttributeBox("Glass Type:", "Not Applicable")}
        {service?.modelName
          ? renderAttributeBox("Model Name:", service?.modelName)
          : null}
        {service?.doorThicknessInches && service?.coreType
          ? renderAttributeBox(
              "Thickness and Core:",
              `${service?.doorThicknessInches} & ${service?.coreType}`
            )
          : null}
        {service?.borePrep
          ? renderAttributeBox("Bore Prep:", service?.borePrep)
          : null}
        {service?.bevel ? renderAttributeBox("Bevel:", service?.bevel) : null}
        {service?.hardwareOptions
          ? renderAttributeBox(
              "Interior Door Hardware Options:",
              service?.hardwareOptions
            )
          : null}
        {service?.prehangingMaterial
          ? renderAttributeBox(
              "Prehanging Material:",
              service?.prehangingMaterial
            )
          : null}
        {service?.wallCondition
          ? renderAttributeBox("Wall Condition:", service?.wallCondition)
          : null}
        {service?.carpetCut
          ? renderAttributeBox("Carpet Cut:", service?.carpetCut)
          : null}
        {service?.stopType
          ? renderAttributeBox("Stop Type:", service?.stopType)
          : null}
        {service?.sawKerf
          ? renderAttributeBox("Saw Kerf for Bullnose:", service?.sawKerf)
          : null}
        {service?.astragal
          ? renderAttributeBox("Astragal:", service?.astragal)
          : null}
        {service?.flushBolts
          ? renderAttributeBox("Flush Bolts:", service?.flushBolts)
          : null}
        {service?.hinges
          ? renderAttributeBox("Hinge Type:", service?.hinges)
          : null}
        {service?.hingeFinish
          ? renderAttributeBox("Hinge Finish:", service?.hingeFinish)
          : null}
        {service?.ballBearingHinges
          ? renderAttributeBox(
              "Ball Bearing Hinges:",
              service?.ballBearingHinges
            )
          : null}
        {service?.hingesPerLeaf
          ? renderAttributeBox(
              "Number of Hinges Per Leaf:",
              service?.hingesPerLeaf
            )
          : null}
        {service?.requestUnitKnockedDown
          ? renderAttributeBox(
              "Request Unit Knocked Down:",
              service?.requestUnitKnockedDown
            )
          : null}
        {service?.labelDoor
          ? renderAttributeBox("Label Door:", service?.labelDoor)
          : null}
        {service?.isFireRated
          ? renderAttributeBox("Fire Rated:", service?.isFireRated)
          : null}
        {service?.isEmergencyExitWindow === true
          ? renderAttributeBox("Meets Egress Requirements:", "Yes")
          : renderAttributeBox("Meets Egress Requirements:", "No")}
        {hasGrid
          ? renderAttributeBox("Grid:", "Yes")
          : renderAttributeBox("Grid:", "No")}
      </>
    );
  }

  if (isLoading) {
    return <Loading />;
  }
  function contains_interior(service_name) {
    console.log("service_name:", service_name);
    return service_name.toLowerCase().includes("interior");
  }

  console.log("projectDetail?.data", projectDetail?.data);

  return (
    <>
      <div className="page-section">
        <Sidebar />
        <Box
          className="page-content"
          sx={{
            width: isDrawerOpen ? `calc(100% - 240px)` : `calc(100% - 57px)`,
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 3,
            }}
          >
            <BeardcrumNavigator breadcrumbs={breadcrumbs ? breadcrumbs : ""} />
          </Box>
          {alert ? (
            <Alert
              severity={responseStatus}
              color={responseStatus}
              sx={{ mb: 3, width: "100%" }}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    dispatch(projectResponseClr(false));
                    setAlertDialog(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {responseMsg}
            </Alert>
          ) : null}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "start",
              flexWrap: "wrap",
              gap: "1rem",
            }}
          >
            <AboutCard>
              <Card
                sx={{
                  background: "#F7F7F7",
                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                  p: 2,
                  mb: 4,
                }}
              >
                <Typography variant="h4">
                  Project Status: {projectDetail?.data?.order_info.orderStatus}
                </Typography>
                <Box
                  component="div"
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    flexWrap: "wrap",
                    gap: "10px",
                    my: 2,
                  }}
                >
                  <StatusBoxes>
                    <Typography
                      sx={{
                        display: "block",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "20px",
                        letterSpacing: "0.25px",
                        color: "rgba(0, 0, 0, 0.87)",
                      }}
                    >
                      {moment(projectDetail.data?.order_info.createdAt).format(
                        "MM/DD/YY"
                      )}
                    </Typography>
                    <Typography
                      sx={{
                        display: "block",
                        fontWeight: "400",
                        fontSize: "10px",
                        lineHeight: "20px",
                        letterSpacing: "0.25px",
                        color: "rgba(0, 0, 0, 0.87)",
                      }}
                    >
                      {moment(projectDetail.data?.order_info.createdAt).format(
                        "hh:mm A"
                      )}
                    </Typography>
                    <Typography
                      sx={{
                        display: "block",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "20px",
                        letterSpacing: "0.25px",
                        color: "rgba(0, 0, 0, 0.87)",
                      }}
                    >
                      Posted
                    </Typography>
                  </StatusBoxes>
                  <StatusBoxes>
                    <Typography
                      sx={{
                        display: "block",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "20px",
                        letterSpacing: "0.25px",
                        color: "rgba(0, 0, 0, 0.87)",
                      }}
                    >
                      {moment(projectDetail.data?.order_info.createdAt).format(
                        "MM/DD/YY"
                      )}
                    </Typography>
                    <Typography
                      sx={{
                        display: "block",
                        fontWeight: "400",
                        fontSize: "10px",
                        lineHeight: "20px",
                        letterSpacing: "0.25px",
                        color: "rgba(0, 0, 0, 0.87)",
                      }}
                    >
                      {moment(projectDetail.data?.order_info.createdAt).format(
                        "hh:mm A"
                      )}
                    </Typography>

                    <Typography
                      sx={{
                        display: "block",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "20px",
                        letterSpacing: "0.25px",
                        color: "rgba(0, 0, 0, 0.87)",
                      }}
                    >
                      Claimed
                    </Typography>
                  </StatusBoxes>
                  <StatusBoxes>
                    <Typography
                      sx={{
                        display: "block",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "20px",
                        letterSpacing: "0.25px",
                        color: "rgba(0, 0, 0, 0.87)",
                      }}
                    >
                      {projectDetail.data?.order_info.orderStatusDate
                        ? moment(
                            projectDetail.data?.order_info.orderStatusDate
                          ).format("MM/DD/YY")
                        : "Please Select date"}
                    </Typography>
                    <Typography
                      sx={{
                        display: "block",
                        fontWeight: "400",
                        fontSize: "10px",
                        lineHeight: "20px",
                        letterSpacing: "0.25px",
                        color: "rgba(0, 0, 0, 0.87)",
                      }}
                    >
                      {/* {(projectDetail.data?.order_info.orderStatusTime) ?  moment(projectDetail.data?.order_info.orderStatusTime, 'HH:mm:ss').format('hh:mm A') : 'Please Select time'} */}
                    </Typography>

                    <Typography
                      sx={{
                        display: "block",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "20px",
                        letterSpacing: "0.25px",
                        color: "rgba(0, 0, 0, 0.87)",
                      }}
                    >
                      Scheduled
                    </Typography>
                    {projectDetail?.data?.order_info.orderStatus ===
                      "Completed" ||
                    projectDetail?.data?.order_info.orderStatus ===
                      "completed" ? (
                      ""
                    ) : (
                      <Button
                        variant="outlined"
                        onClick={handleRescheduleModal}
                      >
                        Reschedule
                      </Button>
                    )}
                  </StatusBoxes>
                  <StatusBoxes>
                    <Typography
                      sx={{
                        display: "block",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "20px",
                        letterSpacing: "0.25px",
                        color: "rgba(0, 0, 0, 0.87)",
                      }}
                    >
                      {"--"}
                    </Typography>
                    <Typography
                      sx={{
                        display: "block",
                        fontWeight: "400",
                        fontSize: "10px",
                        lineHeight: "20px",
                        letterSpacing: "0.25px",
                        color: "rgba(0, 0, 0, 0.87)",
                      }}
                    >
                      {"--"}
                    </Typography>
                    <Typography
                      sx={{
                        display: "block",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "20px",
                        letterSpacing: "0.25px",
                        color: "rgba(0, 0, 0, 0.87)",
                      }}
                    >
                      Materials Ordered
                    </Typography>
                  </StatusBoxes>
                  <StatusBoxes>
                    <Typography
                      sx={{
                        display: "block",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "20px",
                        letterSpacing: "0.25px",
                        color: "rgba(0, 0, 0, 0.87)",
                      }}
                    >
                      {projectDetail?.data?.order_info.orderStatus ===
                        "Completed" ||
                      projectDetail?.data?.order_info.orderStatus ===
                        "completed"
                        ? moment(
                            projectDetail.data?.order_info.orderStatusDate
                          ).format("MM/DD/YY")
                        : "--"}
                    </Typography>
                    <Typography
                      sx={{
                        display: "block",
                        fontWeight: "400",
                        fontSize: "10px",
                        lineHeight: "20px",
                        letterSpacing: "0.25px",
                        color: "rgba(0, 0, 0, 0.87)",
                      }}
                    >
                      {projectDetail?.data?.order_info.orderStatus ===
                        "Completed" ||
                      projectDetail?.data?.order_info.orderStatus ===
                        "completed"
                        ? moment(
                            projectDetail.data?.order_info.orderStatusDate
                          ).format("hh:mm A")
                        : "--"}
                    </Typography>
                    <Typography
                      sx={{
                        display: "block",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "20px",
                        letterSpacing: "0.25px",
                        color: "rgba(0, 0, 0, 0.87)",
                      }}
                    >
                      Completed
                    </Typography>
                  </StatusBoxes>
                </Box>
                {projectDetail?.data?.order_info.orderStatus === "Completed" ||
                projectDetail?.data?.order_info.orderStatus === "completed" ? (
                  ""
                ) : projectDetail?.data?.order_info.orderStatus !==
                  "Cancelled" ? (
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      alignItems: "center",
                      padding: "16px",
                      gap: "11px",
                      background: "#E8E8E8",
                      boxShadow:
                        "0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)",
                    }}
                  >
                    <Typography
                      sx={{
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: "20px",
                        lineHeight: "24px",
                        letterSpacing: "0.15px",
                        textDecoration: "underline",
                        color: "#000000",
                      }}
                    >
                      Project Cancelation:
                    </Typography>
                    <Button variant="outlined" onClick={handleCancelProject}>
                      Cancel Project
                    </Button>
                  </Box>
                ) : null}
                <Box
                  component="div"
                  sx={{
                    background: "#E8E8E8",
                    boxShadow:
                      "0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)",
                    display: "flex",
                    flexWrap: "wrap",
                    marginTop: "15px",
                    flexDirection: "column",
                    padding: "0 16px 16px 16px",
                    gap: "8px",
                    flex: "1",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      paddingTop: "15px",
                      alignItems: "end",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "500",
                        fontSize: "20px",
                        lineHeight: "24px",
                        letterSpacing: "0.15px",
                        textDecoration: "underline",
                      }}
                    >
                      Project Financing Loan ID:
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "500",
                        fontSize: "20px",
                        lineHeight: "24px",
                        marginLeft: "10px",
                        letterSpacing: "0.15px",
                      }}
                    >
                      {projectDetail?.data?.order_info?.loanId}
                    </Typography>
                  </Box>

                  <Box
                    component="div"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      mt: 1,
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography
                        sx={{
                          fontWeight: "500",
                          fontSize: "20px",
                          lineHeight: "24px",
                          letterSpacing: "0.15px",
                          textDecoration: "underline",
                        }}
                      >
                        Project Financing Amount
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: "500",
                          fontSize: "20px",
                          lineHeight: "24px",
                          letterSpacing: "0.15px",
                          ml: 1,
                        }}
                      >
                        {projectDetail?.data?.finance_amount
                          ? `$${parseFloat(
                              projectDetail?.data?.finance_amount
                            ).toFixed(2)}`
                          : null}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Card>
              <Card
                sx={{
                  background: "#F7F7F7",
                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                  p: 2,
                  mb: 4,
                }}
              >
                <Box
                  component="div"
                  sx={{
                    background: "#E8E8E8",
                    boxShadow:
                      "0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)",
                    display: "flex",
                    flexWrap: "wrap",
                    flexDirection: "column",
                    padding: "0 16px 16px 16px",
                    gap: "8px",
                    flex: "1",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "end",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "500",
                        fontSize: "20px",
                        lineHeight: "24px",
                        letterSpacing: "0.15px",
                        textDecoration: "underline",
                      }}
                    >
                      Contracts
                    </Typography>
                  </Box>

                  <Box
                    component="div"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      mt: 1,
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <a
                        href={projectDetail.data?.order_info?.contractLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: "underline", color: "black" }}
                      >
                        <Typography
                          sx={{
                            fontWeight: "500",
                            fontSize: "20px",
                            lineHeight: "24px",
                            letterSpacing: "0.15px",
                          }}
                        >
                          {projectDetail.data?.order_detail[0]?.serviceType}{" "}
                          Contract
                        </Typography>
                      </a>
                      <Typography
                        sx={{
                          fontWeight: "500",
                          fontSize: "20px",
                          lineHeight: "24px",
                          letterSpacing: "0.15px",
                          ml: 1,
                        }}
                      >
                        {" - "}
                        {projectDetail.data?.order_info.user?.firstName +
                          " " +
                          projectDetail.data?.order_info.user?.lastName}
                      </Typography>
                    </Box>
                    {projectDetail?.data?.order_info?.orderStatus !=
                    "Pending" ? (
                      <IconButton
                        component="a"
                        href={projectDetail.data?.order_info?.contractLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <OpenInNewRoundedIcon />
                      </IconButton>
                    ) : (
                      <></>
                    )}
                  </Box>

                  {projectDetail.data?.order_info?.contractorcontractLink && (
                    <Box
                      component="div"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        mt: 1,
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <a
                          href={
                            projectDetail.data?.order_info
                              ?.contractorcontractLink
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            textDecoration: "underline",
                            color: "black",
                          }}
                        >
                          <Typography
                            sx={{
                              fontWeight: "500",
                              fontSize: "20px",
                              lineHeight: "24px",
                              letterSpacing: "0.15px",
                            }}
                          >
                            Work Authorization Contract
                          </Typography>
                        </a>
                        <Typography
                          sx={{
                            fontWeight: "500",
                            fontSize: "20px",
                            lineHeight: "24px",
                            letterSpacing: "0.15px",
                            ml: 1,
                          }}
                        >
                          {" - "}
                          {projectDetail.data?.order_info.user?.firstName +
                            " " +
                            projectDetail.data?.order_info.user?.lastName}
                        </Typography>
                      </Box>
                      <IconButton
                        component="a"
                        href={
                          projectDetail.data?.order_info?.contractorcontractLink
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <OpenInNewRoundedIcon />
                      </IconButton>
                    </Box>
                  )}
                </Box>
              </Card>

              <Card
                sx={{
                  background: "#F7F7F7",
                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                  p: 2,
                  mb: 4,
                }}
              >
                <Typography variant="h4" sx={{ mb: 2 }}>
                  Customer Data
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    gap: "8px",
                    flexWrap: "wrap",
                  }}
                >
                  <Box
                    component="div"
                    sx={{
                      background: "#E8E8E8",
                      boxShadow:
                        "0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)",
                      display: "flex",
                      flexWrap: "wrap",
                      flexDirection: "column",
                      padding: "0 16px 16px 16px",
                      gap: "8px",
                      flex: "1",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "end",
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: "500",
                          fontSize: "20px",
                          lineHeight: "24px",
                          letterSpacing: "0.15px",
                          textDecoration: "underline",
                        }}
                      >
                        Customer:
                      </Typography>
                      {/* <IconButton>
												<OpenInNewRoundedIcon />
											</IconButton> */}
                    </Box>
                    <Typography
                      sx={{
                        fontWeight: "500",
                        fontSize: "20px",
                        lineHeight: "24px",
                        letterSpacing: "0.15px",
                      }}
                    >
                      {projectDetail.data?.order_info.user?.firstName +
                        " " +
                        projectDetail.data?.order_info.user?.lastName}
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "500",
                        fontSize: "20px",
                        lineHeight: "24px",
                        letterSpacing: "0.15px",
                      }}
                    >
                      {projectDetail.data?.order_info.user?.email}
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "500",
                        fontSize: "20px",
                        lineHeight: "24px",
                        letterSpacing: "0.15px",
                      }}
                    >
                      {projectDetail.data?.order_info.user?.phoneNumber}
                    </Typography>
                  </Box>
                  <Box
                    component="div"
                    sx={{
                      background: "#E8E8E8",
                      boxShadow:
                        "0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)",
                      display: "flex",
                      flexWrap: "wrap",
                      flexDirection: "column",
                      padding: "16px 16px 16px 16px",
                      gap: "8px",
                      flex: "1",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: "500",
                          fontSize: "20px",
                          lineHeight: "24px",
                          letterSpacing: "0.15px",
                          textDecoration: "underline",
                        }}
                      >
                        Property:
                      </Typography>
                    </Box>
                    <Typography
                      sx={{
                        fontWeight: "500",
                        fontSize: "20px",
                        lineHeight: "24px",
                        letterSpacing: "0.15px",
                      }}
                    >
                      {projectDetail?.data?.order_detail[0]?.property?.name}
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "500",
                        fontSize: "20px",
                        lineHeight: "24px",
                        letterSpacing: "0.15px",
                      }}
                    >
                      {
                        projectDetail?.data?.order_detail[0]?.property
                          ?.addressOne
                      }
                    </Typography>
                  </Box>
                </Box>
              </Card>
              <Card
                sx={{
                  background: "#F7F7F7",
                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                  p: 2,
                  mb: 4,
                  // display: 'flex', // Added for flexbox layout
                  // alignItems: 'center', // Align items vertically
                  // justifyContent: 'space-between' // Space between items
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography variant="h4" sx={{ mb: 2 }}>
                    {projectDetail.data?.assigned_order?.[0]?.companyName ||
                      "Contractor Company"}
                  </Typography>
                  <Box>
                    <Button variant="contained" onClick={handleAssignModal}>
                      {projectDetail.data?.assigned_order?.length > 0 &&
                      projectDetail.data.assigned_order[0]?.company
                        ? "Reassign"
                        : "Assign"}
                    </Button>
                  </Box>
                </Box>
                {projectDetail.data?.assigned_order?.length > 0 ? (
                  <Box
                    sx={{
                      display: "flex",
                      gap: "8px",
                      flexWrap: "wrap",
                    }}
                  >
                    <Box
                      component="div"
                      sx={{
                        background: "#E8E8E8",
                        boxShadow:
                          "0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)",
                        display: "flex",
                        flexWrap: "wrap",
                        flexDirection: "column",
                        padding: "16px 16px 16px 16px",
                        gap: "8px",
                        flex: "1",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: "500",
                            fontSize: "20px",
                            lineHeight: "24px",
                            letterSpacing: "0.15px",
                            textDecoration: "underline",
                          }}
                        >
                          Primary Contract:
                        </Typography>
                      </Box>
                      <Typography
                        sx={{
                          fontWeight: "500",
                          fontSize: "20px",
                          lineHeight: "24px",
                          letterSpacing: "0.15px",
                        }}
                      >
                        {projectDetail.data?.assigned_order[0].userBy
                          ?.firstName +
                          " " +
                          projectDetail.data?.assigned_order[0].userBy
                            ?.lastName}
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: "500",
                          fontSize: "20px",
                          lineHeight: "24px",
                          letterSpacing: "0.15px",
                        }}
                      >
                        {projectDetail.data?.assigned_order[0].userBy?.email}
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: "500",
                          fontSize: "20px",
                          lineHeight: "24px",
                          letterSpacing: "0.15px",
                        }}
                      >
                        {
                          projectDetail.data?.assigned_order[0].userBy
                            ?.phoneNumber
                        }
                      </Typography>
                    </Box>
                    <Box
                      component="div"
                      sx={{
                        background: "#E8E8E8",
                        boxShadow:
                          "0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)",
                        display: "flex",
                        flexWrap: "wrap",
                        flexDirection: "column",
                        padding: "16px 16px 16px 16px",
                        gap: "8px",
                        flex: "1",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: "500",
                            fontSize: "20px",
                            lineHeight: "24px",
                            letterSpacing: "0.15px",
                            textDecoration: "underline",
                          }}
                        >
                          Staff Assigned:
                        </Typography>
                      </Box>
                      <Typography
                        sx={{
                          fontWeight: "500",
                          fontSize: "20px",
                          lineHeight: "24px",
                          letterSpacing: "0.15px",
                        }}
                      >
                        {projectDetail.data?.assigned_order[0].userTo
                          ?.firstName +
                          " " +
                          projectDetail.data?.assigned_order[0].userTo
                            ?.lastName}
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: "500",
                          fontSize: "20px",
                          lineHeight: "24px",
                          letterSpacing: "0.15px",
                        }}
                      >
                        {projectDetail.data?.assigned_order[0].userTo?.email}
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: "500",
                          fontSize: "20px",
                          lineHeight: "24px",
                          letterSpacing: "0.15px",
                        }}
                      >
                        {
                          projectDetail.data?.assigned_order[0].userTo
                            ?.phoneNumber
                        }
                      </Typography>
                    </Box>
                  </Box>
                ) : null}
              </Card>
            </AboutCard>
            <ActivityLog />
          </Box>

          <Card
            sx={{
              background: "#F7F7F7",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              p: 2,
              mb: 4,
              display: "flex",
              flexDirection: "column",
              gap: "1.7rem",
            }}
          >
            <Typography variant="h4">Financials</Typography>

            <Box
              component="div"
              sx={{
                background: "#E8E8E8",
                boxShadow:
                  "0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)",
                display: "flex",
                flexWrap: "wrap",
                flexDirection: "column",
                padding: "16px 16px 16px 16px",
                gap: "8px",
                flex: "1",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "500",
                  fontSize: "20px",
                  lineHeight: "24px",
                  letterSpacing: "0.15px",
                  textDecoration: "underline",
                  mb: 2,
                }}
              >
                Customer:
              </Typography>
              <Typography
                sx={{
                  fontWeight: "500",
                  fontSize: "20px",
                  lineHeight: "24px",
                  letterSpacing: "0.15px",
                }}
              >
                Downpayment
              </Typography>
              <ProjectCustomersTable transactionType="Initial Payment" />
              <Typography
                sx={{
                  fontWeight: "500",
                  fontSize: "20px",
                  lineHeight: "24px",
                  letterSpacing: "0.15px",
                }}
              >
                Verification Payment
              </Typography>
              <ProjectCustomersTable transactionType="Verification & Prep Work" />
              <Typography
                sx={{
                  fontWeight: "500",
                  fontSize: "20px",
                  lineHeight: "24px",
                  letterSpacing: "0.15px",
                }}
              >
                Completion Payment
              </Typography>
              <ProjectCustomersTable transactionType="Completion" />
            </Box>
            <Box
              component="div"
              sx={{
                background: "#E8E8E8",
                boxShadow:
                  "0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)",
                display: "flex",
                flexWrap: "wrap",
                flexDirection: "column",
                padding: "16px 16px 16px 16px",
                gap: "8px",
                flex: "1",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "600",
                  fontSize: "20px",
                  lineHeight: "24px",
                  letterSpacing: "0.15px",
                  textDecoration: "underline",
                }}
              >
                Contractor:
              </Typography>
              <ProjectContractsTable data={projectDetail.data?.order_info} />
            </Box>
            <Box
              component="div"
              sx={{
                background: "#E8E8E8",
                boxShadow:
                  "0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)",
                display: "flex",
                flexWrap: "wrap",
                flexDirection: "column",
                padding: "16px 16px 16px 16px",
                gap: "8px",
                flex: "1",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "500",
                  fontSize: "20px",
                  lineHeight: "24px",
                  letterSpacing: "0.15px",
                  textDecoration: "underline",
                }}
              >
                Accounting Breakdown:
              </Typography>
              <ProjectAccountingBreakdown
                pricingCalculatorValues={
                  projectDetail?.data?.pricing_calculator_values
                }
              />
            </Box>
          </Card>

          <Card
            sx={{
              background: "#F7F7F7",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              p: 2,
              mb: 4,
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            <Typography variant="h4">Conversation</Typography>
            {chatsData?.map((chat) => {
              const { Messages, Contractor, Customer } = chat;

              const { seconds } = Messages[0]?.MessageDetails.DateTime;
              const timestamp = moment.unix(seconds);

              const timeAgo = moment(timestamp).fromNow();

              const person = Contractor.ID
                ? "Contractor"
                : Customer.ID
                ? "Customer"
                : "";

              return (
                <Box
                  component="div"
                  key={chat.ProjectID}
                  sx={{
                    background: "#E8E8E8",
                    boxShadow:
                      "0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)",
                    display: "flex",
                    flexDirection: "column",
                    gap: "4px",
                    width: "fit-content",
                    p: "8px",
                    borderRadius: "4px",
                  }}
                >
                  <Typography sx={{ fontSize: "18px", fontWeight: "600" }}>
                    Joel ({person}):
                  </Typography>
                  <Typography sx={{ fontSize: "14px" }}>
                    {Messages[0]?.MessageDetails.Body}
                  </Typography>
                  <Typography sx={{ fontSize: "10px" }}>{timeAgo}</Typography>
                </Box>
              );
            })}
          </Card>

          <Card
            sx={{
              background: "#F7F7F7",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              p: 2,
              mb: 4,
              display: "flex",
              flexDirection: "column",
              gap: "1.5rem",
            }}
          >
            <Typography variant="h4">Service</Typography>
            <OuterGrid sx={{ width: "100%", ml: 0 }}>
              {projectDetail.data?.order_detail.map((service) => {
                return (
                  <WhiteCard key={service?._id}>
                    {/* doorTypes*/}

                    <WhiteCardHeading>
                      {" "}
                      {contains_interior(service?.serviceName)
                        ? "Double Door (Entry)"
                        : service?.serviceName}
                    </WhiteCardHeading>
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        alignItems: "self-start",
                        gap: "1.4rem",
                        mt: 2,
                      }}
                    >
                      <Box
                        sx={{
                          background: "#FFFFFF",
                          borderRadius: "10px",
                          p: 2,
                        }}
                      >
                        <img src="/images/service1.png" alt="service" />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          flex: "1",
                        }}
                      >
                        {contains_interior(service?.serviceName)
                          ? renderAttributes(service)
                          : renderOtherAttrs(service)}
                      </Box>
                    </Box>
                  </WhiteCard>
                );
              })}
            </OuterGrid>
          </Card>
          <Card
            sx={{
              background: "#F7F7F7",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              p: 2,
              mb: 4,
              display: "flex",
              flexDirection: "column",
              gap: "1.5rem",
            }}
          >
            <Typography variant="h4">Warranty</Typography>
            <WarrantyCard>
              <ErrorOutlineRoundedIcon sx={{ fontSize: "50px" }} />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "500",
                    letterSpacing: "0.1px",
                  }}
                >
                  Labor warranty expries: 03/31/2023
                </Typography>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "500",
                    letterSpacing: "0.1px",
                  }}
                >
                  Product warranty expries: 03/31/2024
                </Typography>
              </Box>
            </WarrantyCard>
          </Card>
        </Box>

        {/* modals  */}
        {isReschedule ? (
          <Dialog
            open={isReschedule}
            scroll="body"
            onClose={handleRescheduleModal}
            aria-describedby="scroll-dialog-description"
          >
            <DialogTitle
              id="scroll-dialog-title"
              sx={{
                p: 1,
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "24px",
                lineHeight: "24px",
                letterSpacing: "0.18px",
                color: "#000000",
              }}
            >
              Reschedule
            </DialogTitle>
            {console.log("rescheduleInitialValues: ", rescheduleInitialValues)}
            <Formik
              initialValues={rescheduleInitialValues}
              onSubmit={(values, formikHelpers) => {
                values.orderStatusDate = dayjs(values.orderStatusDate);
                values.orderStatusTime = dayjs(
                  projectTimeFormatted,
                  "HH:mm:ss"
                );
                values.orderStatus = "Scheduled";
                console.log(values);
                dispatch(rescheduleProject(values))
                  .then(() => {
                    window.location.reload();
                  })
                  .catch((error) => {
                    console.error("Error rescheduling project:", error);
                  });
                handleRescheduleModal();
              }}
              validationSchema={object({
                orderStatusDate: Yup.date().required(
                  "Please enter a valid date"
                ),
                // orderStatusTime: Yup.string().required('Please enter valid time')
              })}
            >
              {({ errors, touched, isValid, dirty, values, setFieldValue }) => (
                <Form>
                  <RescheduleContainer>
                    <ModalHeadingPopUp>
                      {projectDetail.data?.order_detail?.length > 0 &&
                      projectDetail.data?.order_detail[0].service
                        ? projectDetail.data?.order_detail[0].service.name
                        : ""}
                      {projectDetail?.data?.order_info
                        ? " " + projectDetail?.data?.order_info?.uniqueOrderID
                        : " " + param.projectid}
                    </ModalHeadingPopUp>

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer
                        components={["DateTimePicker"]}
                        sx={{ width: "100%", mb: 4 }}
                      >
                        <DatePicker
                          disablePast
                          label="Date"
                          value={
                            values.orderStatusDate &&
                            dayjs(values.orderStatusDate).isValid()
                              ? dayjs(values.orderStatusDate)
                              : dayjs()
                          }
                          onChange={(newValue) =>
                            setFieldValue("orderStatusDate", newValue)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              error={Boolean(
                                touched.orderStatusDate &&
                                  errors.orderStatusDate
                              )}
                              helperText={
                                touched.orderStatusDate &&
                                errors.orderStatusDate
                              }
                              label="Date"
                              name="orderStatusDate"
                              variant="outlined"
                              fullWidth
                            />
                          )}
                        />
                      </DemoContainer>
                    </LocalizationProvider>

                    {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DateTimePicker']}>
              
                        <TimePicker 
                            label="Time"
                            value={values.orderStatusTime
                                   ?dayjs(values.orderStatusTime, 'HH:mm:ss') 
                                   :  dayjs()}
                            onChange={(newValue) => setFieldValue("orderStatusTime", newValue.format('HH:mm:ss'))}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    error={Boolean(touched.orderStatusTime && errors.orderStatusTime)}
                                    helperText={touched.orderStatusTime && errors.orderStatusTime}
                                    label="Time"
                                    name="orderStatusTime"
                                    variant="outlined"
                                    fullWidth
                      
                                />
                            )}
                        />
                    </DemoContainer>
                </LocalizationProvider> */}
                  </RescheduleContainer>

                  <DialogActions>
                    <Button
                      variant="outlined"
                      className="bc-btn-outline"
                      onClick={handleRescheduleModal}
                    >
                      <b> Cancel</b>
                    </Button>
                    <Button
                      disabled={!dirty || !isValid}
                      className="bc-btn-outline"
                      type="submit"
                      variant="contained"
                    >
                      <b> Reschedule</b>
                    </Button>
                  </DialogActions>
                </Form>
              )}
            </Formik>
          </Dialog>
        ) : null}
        {isReassign ? (
          <Dialog
            open={isReassign}
            scroll="body"
            onClose={handleReassignModal}
            aria-describedby="scroll-dialog-description"
          >
            <DialogTitle
              id="scroll-dialog-title"
              sx={{
                p: 1,
                fontSize: "24px",
                lineHeight: "24px",
                fontWeight: "400",
                letterSpacing: "0.18px",
                fontStyle: "normal",
                color: "#000000",
              }}
            >
              Reassign
            </DialogTitle>
            <Formik
              initialValues={reassginInitialValues}
              onSubmit={(values, formikHelpers) => {
                // values.userTo = projectDetail.data.order_info.user._id;
                values.order = param.projectid;
                values.assignedDate = projectDateFormatted;

                dispatch(assignProjectToUser(values));
                handleReassignModal();
                console.log("values: s", values);
              }}
              validationSchema={object({})}
            >
              {({
                errors,
                touched,
                isValid,
                dirty,
                values,
                setFieldValue,
                handleChange,
              }) => (
                <Form>
                  <RescheduleContainer sx={{ mb: 14 }}>
                    <ModalHeadingPopUp>
                      {projectDetail.data?.order_detail.length > 0 &&
                      projectDetail.data?.order_detail[0].service
                        ? projectDetail.data?.order_detail[0].service.name
                        : ""}{" "}
                      {projectDetail?.data?.order_info?.uniqueOrderID ||
                        param.projectid}
                    </ModalHeadingPopUp>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {staffReassignedList.data?.map((assignedStaff) => {
                        console.log(
                          "assignedStaffassignedStaff ",
                          assignedStaff
                        );
                        const { firstName, lastName, _id } =
                          assignedStaff.userTo;
                        return (
                          <AssignBox key={_id + "assignedStaff"}>
                            <Typography
                              sx={{ fontWeight: "600", color: "#000000" }}
                            >
                              {firstName + " " + lastName}
                            </Typography>
                            <ReAssignRemove
                              type="button"
                              onClick={() =>
                                handleDeleteAssignStaff(assignedStaff?._id)
                              }
                            >
                              Remove
                            </ReAssignRemove>
                          </AssignBox>
                        );
                      })}
                      <AssignBox>
                        <select
                          name="userTo"
                          id="userTo"
                          style={{
                            background: "#FFFFFF",
                            height: "30px",
                            width: "184px",
                            outline: "none",
                            border: "none",
                            fontWeight: "bold",
                          }}
                          ref={selectRef}
                          onChange={handleChange} // Ensure this line is added
                          value={values.userTo} // Ensure the select's value is managed by Formik
                        >
                          {listofAssignStaff.data?.map((assignStaff) => {
                            const { firstName, lastName, _id } = assignStaff;
                            return (
                              <option value={_id} key={_id + "assignStaff"}>
                                {firstName + " " + lastName}
                              </option>
                            );
                          })}
                        </select>
                        <ReAssignRemove
                          type="submit"
                          sx={{ background: "#019EB2" }}
                        >
                          assign
                        </ReAssignRemove>
                      </AssignBox>
                    </Box>
                  </RescheduleContainer>
                  <DialogActions>
                    <Button variant="outlined" onClick={handleReassignModal}>
                      Cancel
                    </Button>
                    <Button type="submit" variant="contained">
                      Save Changes
                    </Button>
                  </DialogActions>
                </Form>
              )}
            </Formik>
          </Dialog>
        ) : null}
        {isAssign ? (
          <Dialog
            open={isAssign}
            scroll="body"
            onClose={handleAssignModal}
            aria-describedby="scroll-dialog-description"
            sx={{
              "& .MuiDialog-paper": {
                // This targets the Paper component inside the Dialog
                borderRadius: "10px", // Set your desired border-radius value here
                boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)", // This adds shadow
              },
            }}
          >
            <DialogTitle
              id="scroll-dialog-title"
              sx={{
                p: 1,
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "24px",
                lineHeight: "24px",
                letterSpacing: "0.18px",
                color: "#000000",
                boxSizing: "border-box", // Add this property to the dropdown's style
              }}
            >
              &nbsp;&nbsp;Assign Company
            </DialogTitle>
            <Formik
              initialValues={reassginInitialValues}
              onSubmit={async (values, formikHelpers) => {
                values.userTo = projectDetail.data.order_info.user._id;
                values.order = param.projectid;
                values.assignedDate = new Date();
                values.company = selectedCompany;
                setSelectionChanged(false);

                // Assuming assignCompanyToOrder returns a promise.
                await dispatch(assignCompanyToOrder(values))
                  .then(() => {
                    // After assignCompanyToOrder successfully completes, dispatch singleProjectDetail.
                    dispatch(singleProjectDetail(param.projectid));
                  })
                  .catch((error) => {
                    // Handle any errors from assignCompanyToOrder here.
                    console.error("Error assigning company to order:", error);
                  });

                handleAssignModal();
              }}
              validationSchema={object({})}
            >
              {({ values, setFieldValue, setFieldTouched }) => {
                const handleSelectChange = (company) => {
                  setFieldValue("company", company._id); // Update Formik's state
                  setSelectedCompany(company._id); // Update local state to manage button enablement
                };

                return (
                  <Form>
                    <RescheduleContainer sx={{ mb: 14 }}>
                      <ModalHeadingPopUp>
                        {projectDetail.data?.order_detail?.length > 0 &&
                        projectDetail.data?.order_detail[0].service
                          ? projectDetail.data?.order_detail[0].service.name
                          : ""}{" "}
                        {projectDetail?.data?.order_info?.uniqueOrderID ||
                          param.projectid}
                      </ModalHeadingPopUp>
                      <br />
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <ReAssignBox>
                          <CustomDropdown
                            listofCompanies={listofCompanies}
                            onSelectCompany={handleSelectChange} // Pass the handler
                            setFieldValue={setFieldValue} // Pass setFieldValue to CustomDropdown
                          />
                        </ReAssignBox>
                      </Box>
                    </RescheduleContainer>
                    <DialogActions>
                      <Button variant="outlined" onClick={handleAssignModal}>
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        variant="contained"
                        // The button will be disabled if the 'company' field in Formik's state is not set
                        disabled={
                          !values.company ||
                          values.company === "" ||
                          !selectionChanged
                        }
                      >
                        Assign
                      </Button>
                    </DialogActions>
                  </Form>
                );
              }}
            </Formik>
          </Dialog>
        ) : null}
      </div>
    </>
  );
};

export default ProjectDetails;
