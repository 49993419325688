import React, { useEffect } from 'react';
import "./App.css";
import { BrowserRouter, Routes, Route, Navigate, useNavigate } from "react-router-dom";
import Login from "./pages/Login";
import Forgot from "./pages/Forgot";
import Confirmation from "./pages/Confirmation";
import SetPassword from "./pages/SetPassword";
import Services from "./pages/Services/Services";
import ServiceProductsContainer from './pages/Services/ServiceProductsContainer';
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import { CssBaseline } from "@mui/material";
import { Provider, useSelector } from "react-redux";
import store from './store';
import Layout from "./components/Layout";
import Customers from "./pages/customers/Customers";
import Companies from "./pages/companies/Companies";
import Transactions from "./pages/transactions/Transactions";
import Coupons from "./pages/coupons/Coupons";
import AdminPortalUser from "./pages/admin-portal-user/AdminPortalUser";
import CompanyInfo from "./pages/companies/CompanyInfo";
import SystemVariables from "./pages/system-variables/SystemVariables";
import CustomerDetails from "./pages/customers/CustomerDetails";
import Projects from "./pages/projects/Projects";
import ProjectDetails from "./pages/projects/ProjectDetails";
import AddCoupon from "./pages/coupons/AddCoupon";
import CouponLayout from "./pages/coupons/CouponLayout";
import EditCoupon from "./pages/coupons/EditCoupon";
import HealthCheck from "./pages/HealthCheck";
import ProtectedRoute from './ProtectedRoute';
import useRehydrateLoginState from './RehydrationHook';
import DeleteUser from './pages/DeleteUser';
import Header from "./components/Header";
import { jwtDecode } from 'jwt-decode';

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <AppContent />
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  );
}

function AppContent() {
  useRehydrateLoginState();
  const isAuthenticated = useSelector((state) => state.login.storeUserToken);
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      const token = localStorage.getItem("token");
      if (token) {
        try {
          const decodedToken = jwtDecode(token);
          const currentTime = Date.now() / 1000;

          if (decodedToken.exp < currentTime) {
            localStorage.removeItem("token");
            navigate('/login');
          }
        } catch (error) {
          console.error("Invalid token", error);
          localStorage.removeItem("token");
          navigate('/login');
        }
      }
    }
  }, [isAuthenticated, navigate]);

  return (
    <>
      {isAuthenticated && <Header />}
      <Routes>
        <Route
          path="/"
          element={
            isAuthenticated ? <Navigate to="/services" /> : <Navigate to="/login" />
          }
        />
        <Route path="/" element={<Layout />}>
          <Route path="/services" element={<ProtectedRoute><Services /></ProtectedRoute>} />
          <Route path="/health-check" element={<HealthCheck />} />
          <Route path="/services/:serviceid" element={<ProtectedRoute><ServiceProductsContainer /></ProtectedRoute>} />
          <Route path="/companies" element={<ProtectedRoute><Companies /></ProtectedRoute>} />
          <Route path="/companies/:companyid" element={<ProtectedRoute><CompanyInfo /></ProtectedRoute>} />
          <Route path="/customers" element={<ProtectedRoute><Customers /></ProtectedRoute>} />
          <Route path="/customers/:customerid" element={<ProtectedRoute><CustomerDetails /></ProtectedRoute>} />
          <Route path="/projects" element={<ProtectedRoute><Projects /></ProtectedRoute>} />
          <Route path="/projects/:projectid" element={<ProtectedRoute><ProjectDetails /></ProtectedRoute>} />
          <Route path="/transactions" element={<ProtectedRoute><Transactions /></ProtectedRoute>} />
          <Route path="/coupons" element={<ProtectedRoute><CouponLayout /></ProtectedRoute>}>
            <Route path="" element={<Coupons />} />
            <Route path="addCoupon" element={<ProtectedRoute><AddCoupon /></ProtectedRoute>} />
            <Route path="editCoupon/:couponid" element={<ProtectedRoute><EditCoupon /></ProtectedRoute>} />
          </Route>
          <Route path="/admin-portal-user" element={<ProtectedRoute><AdminPortalUser /></ProtectedRoute>} />
          <Route path="/system-variables" element={<ProtectedRoute><SystemVariables /></ProtectedRoute>} />
        </Route>
        <Route path="/forgot-password" element={<Forgot />} />
        <Route path="/confirm-password" element={<Confirmation />} />
        <Route path="/set-password" element={<SetPassword />} />
        <Route path="/login" element={<Login />} />
        <Route path="/delete-user" element={<DeleteUser />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
}

function NotFound() {
  const navigate = useNavigate();
  return (
    <>
      <h1>Page not Found</h1>
      <button onClick={() => navigate(-1)}>Go back</button>
    </>
  );
}

export default App;
