import React from 'react'
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton, Menu, MenuItem, Typography } from '@mui/material';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { logout, logoutUser } from '../features/login/loginSlice';
import { Link, useNavigate } from 'react-router-dom';
const Header = () => {
  const {loggedInUser, storeUserToken} = useSelector((store) => store.login)
const  dispatch = useDispatch()
const  navigate = useNavigate()
  // console.log('asdsadsdadasd3432rad',loggedInUser);

  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleChange = (event) => {
    setAuth(event.target.checked);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleNavigateToAdminPortal = () => {
    navigate('/admin-portal-user', { 
      state: { 
        userData: loggedInUser?.data?.userData, 
        openModal: true 
      } 
    });
  };
  
  

  const handleClose = () => {
    setAnchorEl(null);
  };
const handleLogout = () => {
  console.log('Logout handler')
  navigate(logoutUser(loggedInUser?.data?.userData?._id))
  localStorage.clear();
  dispatch(logout());
  navigate('/login');
}

  return (
  
      <AppBar position="sticky" sx={{ alignItems: 'center', height: '60px', width: '100%' }}>
        <Toolbar sx={{ justifyContent: storeUserToken ? 'space-between' : 'center', width: '100%' }}>
          <img src="/images/Logo_orange.png" alt="reli-logo" />
          {storeUserToken && (
            <div>
              <Typography sx={{ textTransform: 'capitalize', display: 'inline-block' }}>
                {loggedInUser.data?.userData?.firstName || ''} {loggedInUser.data?.userData?.lastName || ''}
              </Typography>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
                sx={{ borderRadius: '0', ml: 1 }}
              >
                <KeyboardArrowDownRoundedIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom', 
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleNavigateToAdminPortal}>
                  My Account
                </MenuItem>
                <MenuItem><Link onClick={() => handleLogout()} style={{ color: 'black' }}>Logout</Link></MenuItem>
              </Menu>
            </div>
          )}
        </Toolbar>
      </AppBar>
    );
    
  
}

export default Header