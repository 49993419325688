import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableContainer, TableHead, TableRow, TablePagination, Paper, TextField, Button } from '@mui/material';
import { StyledTableCell, StyledTableRow } from './StyledTableComponents';
import ServiceTableHeader from './ServiceTableHeader';

const ServiceTable = React.memo(({
  data:filteredData,
  columns,
  searchValue,
  editingRow,
  editedPrices,
  onEditPrice,
  onPriceChange,
  onSavePrice,
  priceFieldName 
}) => {
  console.log('priceFieldNamepriceFieldNamepriceFieldName' ,filteredData)
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  useEffect(()=>{
    setPage(0)
  },[searchValue])
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handlePriceInputChange = (rowId, value) => {
    const newValue = value.replace(/[^\d.]/g, '').replace(/(\..*)\./g, '$1');
    onPriceChange(rowId, newValue);
  };

  const handleSavePrice = (rowId) => {
    let priceToSave = parseFloat(editedPrices[rowId].replace('$', ''));
    priceToSave = isNaN(priceToSave) ? '0.00' : priceToSave.toFixed(2);
    onSavePrice(rowId, priceToSave, priceFieldName); // Now passing the price field name
  };

  const formatCurrency = (value) => {
    if (typeof value === 'string' && value.startsWith('$')) {
      value = parseFloat(value.substring(1));
    } else {
      value = parseFloat(value);
    }

    if (!isNaN(value)) {
      return `$${value.toFixed(2)}`;
    } else {
      return '$0.00';
    }
  };


  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <ServiceTableHeader columns={columns} />
          </TableHead>
          <TableBody>
            {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
              <StyledTableRow key={row._id}>
                {columns.map((column) => {
                  const isEditingPrice = editingRow === row._id && column.id === priceFieldName;
                  const displayValue = column.id === priceFieldName && !editingRow ? formatCurrency(row[column.id]) : row[column.id];
                  return (
                    <StyledTableCell key={column.id}>
                      {isEditingPrice ? (
                        <TextField
                          value={editedPrices[row._id] || ''}
                          onChange={(e) => handlePriceInputChange(row._id, e.target.value)}
                          size="small"
                          margin="none"
                          InputProps={{
                            startAdornment: <span>$</span>,
                          }}
                        />
                      ) : (
                        displayValue  
                      )}
                      {column.id === 'actions' ? (
                        editingRow === row._id ? (
                          <Button onClick={() => handleSavePrice(row._id)} size="small" variant="contained">
                            Save
                          </Button>
                        ) : (
                          <Button onClick={() => onEditPrice(row._id, row[priceFieldName], priceFieldName)} size="small" variant="outlined">
                            Edit
                          </Button>
                        )
                      ) : null}
                    </StyledTableCell>
                  );
                })}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={filteredData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
});

export default ServiceTable;