import React, { useState, useEffect } from 'react';
import { Card, Box, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';

const Root = styled('div')(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
        width: '100%',
        borderBottom: '1px solid #979797',
    },
    [theme.breakpoints.up('md')]: {
        width: '360px',
    },
}));

const StyledBox = styled(Box)(({ theme, isLast, isAlternate }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    borderBottom: '1px solid #979797',
    py: 2,
    background: isLast 
        ? '#D2E2F1' 
        : isAlternate 
            ? '#F8E5D0' 
            : 'white'
}));

const ValueBox = styled(Box)(({ theme }) => ({
    display: 'flex',    
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '130px',
    px: 1,
}));

const ProjectAccountingBreakdown = ({ pricingCalculatorValues }) => {
    const [labeledValues, setLabeledValues] = useState({});
    const [isLoading, setIsLoading] = useState(true); // Added isLoading state

    useEffect(() => {
        if (pricingCalculatorValues!=null) {
            const values = {
                "Order Materials Price": pricingCalculatorValues.orderMaterialsPrice,
                "Order Reli Materials Service Fee": pricingCalculatorValues.orderReliMaterialsServiceFee,
                "Sales Tax": pricingCalculatorValues.salesTax,
                "Order Labor Price": pricingCalculatorValues.orderLaborPrice,
                "Order Reli Labor Service Fee": pricingCalculatorValues.orderReliLaborServiceFee,
                "Cart Total": pricingCalculatorValues.cartTotal,
                "Coupon Discount": pricingCalculatorValues.couponDiscount,
                "Discount": pricingCalculatorValues.discount,
                "Order Permit Fee": pricingCalculatorValues.orderPermitFee,
                "Subtotal": pricingCalculatorValues.subtotal,
                "Merchant Fees": pricingCalculatorValues.merchantFees,
                "Total Customer will Pay": pricingCalculatorValues.totalCustomerWillPay,
                "Total Paid to Contractor": pricingCalculatorValues.totalPaidToContractor,
                "Total Paid to Stripe": pricingCalculatorValues.totalPaidToStripe,
                "Total Paid to Reli": pricingCalculatorValues.totalPaidToReli,
                "Total Paid to Materials Supplier": pricingCalculatorValues.totalPaidToMaterialsSupplier,
                "EST Reli Earnings": pricingCalculatorValues.estReliEarnings
            };
            setLabeledValues(values);
            setIsLoading(false);

        }
    }, [pricingCalculatorValues]);
    
     if (isLoading) {
        return (
            <Root>
                <Card variant="outlined" sx={{ py: 2, px: 1 }}>
                    <Typography>No data available</Typography>
                </Card>
            </Root>
        );
    }

    return (
        <Root>
            <Card variant="outlined" sx={{ py: 2, px: 1 }}>
                {labeledValues && Object.entries(labeledValues).map(([key, value], index) => (
                    <StyledBox 
                        key={index} 
                        isLast={index === Object.entries(labeledValues).length - 1}
                        isAlternate={index % 2 === 1}
                    >
                        <Typography>{key}</Typography>
                        <ValueBox>
                            <Box>$</Box>
                            <Box>{parseFloat(value).toFixed(2)}</Box>
                        </ValueBox>
                    </StyledBox>
                ))}
            </Card>
        </Root>
    );
}

export default ProjectAccountingBreakdown;