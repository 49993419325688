import { Box, Table, TableContainer, Typography, IconButton, Button, ButtonGroup } from '@mui/material';
import React, { useEffect } from 'react'
import Paper from '@mui/material/Paper';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import AddUserModal from './AddUserModal';
import EditUserModal from './EditUserModal';
import { useDispatch, useSelector } from 'react-redux';
import {  handleEditUserModal } from '../../features/login/loginSlice';
import { getUsers, userInfoResponseClr } from '../../features/userInfo/userInfoSlice';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import Loading from '../../components/Loading';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import Alert  from "@mui/material/Alert";
import CloseIcon from '@mui/icons-material/Close';
import FilterListIcon from '@mui/icons-material/FilterList';

// user info
const userInfoColumns = [
  { id: 'firstName', label: 'Name', minWidth: 150, fontWeight: '600' },
  { id: 'email', label: 'Email', minWidth: 150, fontWeight: '600' },
  { id: 'phoneNumber', label: 'Phone', minWidth: 150, fontWeight: '600' },
  { id: 'status', label: 'Status', minWidth: 80, fontWeight: '600' },
  { id: 'approvedByReli', label: 'Approved by Reli', minWidth: 120, fontWeight: '600', textTransform: 'capitalize' },
  { id: 'accountType', label: 'Type', minWidth: 100, fontWeight: '600', textTransform: 'capitalize' },
  { id: 'updatedAt', label: 'Last Active', minWidth: 200, fontWeight: '600' },
  // { id: 'actions', label: 'Actions', minWidth: 130, fontWeight: '600' },
];
const SortButton = styled(Button)(({ theme }) => ({
  // Add your styles here to match the button in the image
  margin: theme.spacing(2),
  padding: theme.spacing(1),
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.gray,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
    backgroundColor: '#ddd',
  },
}));

const UserInfo = () => {
const dispatch = useDispatch();
const param = useParams();
const {isAddUserModal ,isEditUserModal} = useSelector((store) => store.login)
const {alert, users, isLoading, responseStatus, responseMsg , isUpdated} = useSelector((store) => store.userInfo)
const [page, setPage] = useState(0);
const [rowsPerPage, setRowsPerPage] = useState(10);
const [searchValue, setSearchValue] = useState('');
const [alertDialog, setAlertDialog] = React.useState(false);
const [selectedUserId, setSelectedUserId] = useState(null);

const handleChangePage = (event, newPage) => {
  setPage(newPage);
};
const handleChangeRowsPerPage = (event) => {
  setRowsPerPage(+event.target.value);
  setPage(0);
};
const handleSearch = (searchedValue) => {
  setSearchValue(searchedValue)
  setPage(0)
}

useEffect(() => {
  dispatch(getUsers(param.companyid));
}, [dispatch, param.companyid, isUpdated]);

const [sortDirection, setSortDirection] = React.useState('desc'); // 'asc' for ascending, 'desc' for descending
const [sortedUsers, setsortedUsers] = React.useState([]);

const sortLastLogin = () => {
  
  const sortedData = [...users?.data].sort((a, b) => {
    console.log(a)
    const dateA = new Date(a?.updatedAt);
    const dateB = new Date(b?.updatedAt);
    return sortDirection === 'asc' ? dateB - dateA : dateA - dateB;
  });

  setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc'); // Toggle sort direction
  setsortedUsers(sortedData);
};
const filteredUsers = sortedUsers.filter((data) => data.firstName?.toLowerCase().includes(searchValue) || data.email?.toLowerCase().includes(searchValue) || data.phone?.toLowerCase().includes(searchValue));

useEffect(() => {
  if (users?.data) {
    setsortedUsers(users.data);
  }
}, [users?.data]);

if(isLoading){
  return (
    <Loading/>
  )
}
  return (
    <>
      <Box component="div" sx={{
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        alignItems: 'center',
        mb: 3,
      }}>
        <Box component="div">
            {/* <SearchBox/> */}
            <Box sx={{
            background: '#FFFFFF',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            borderRadius: '33px',
            height: 50,
            display: 'flex',
            alignItems: 'center',
            maxWidth: '245px',
            border: '1px solid #ddd',
            overflow: 'hidden'
        }}>
            <SearchRoundedIcon sx={{
              width: '16%',
              marginLeft: '6px'
            }}/>
            <input type="text" value={searchValue} className='search-input' onChange={(e) =>  handleSearch(e.target.value.toLowerCase())} />
        </Box>
        </Box>
        <SortButton
              startIcon={(<IconButton aria-label="filter-icon" >
              <FilterListIcon/>
            </IconButton> )}
            onClick={sortLastLogin}
            >
          
        </SortButton> 
        {/* <IconButton aria-label="filter-icon" size="large">
          <FilterListRoundedIcon />
        </IconButton> */}
        {/* <Button variant="outlined"  className="bc-btn-outline" color="primary" onClick={() => dispatch(handleAddUserModal())}>add user</Button> */}
      </Box>

            {
              alert ? (
                <Alert 
                  severity={responseStatus}
                  color={responseStatus} 
                  sx={{mb: 3, width: '100%'}}
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        dispatch(userInfoResponseClr(false));
                        setAlertDialog(false)
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >{responseMsg}</Alert>
              ) : null
            }

      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer>
              <Table stickyHeader aria-label="sticky table" sx={{}}>
              <TableHead>
                  <StyledTableRow>
                  {userInfoColumns.map((column) => (
                      <StyledTableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth, fontWeight: column.fontWeight }}
                      >
                      {column.label}
                      </StyledTableCell>
                  ))}
                  </StyledTableRow>
              </TableHead>
              <TableBody>
                  {sortedUsers?.filter((data) => data.firstName?.toLowerCase().includes(searchValue) || data.email?.toLowerCase().includes(searchValue) || data.phone?.toLowerCase().includes(searchValue))?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                      return (

                      <StyledTableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                        {userInfoColumns.map((column) => {
          const value = row[column.id];

          return (
              <StyledTableCell key={column.id+'UserInfo'}  align={column.align} style={{ textTransform: column.textTransform}}>
              {column.id === 'status' ? 
                  value === true? 'Enable' : 'Disable' 
              : column.id === 'accountType' ? 
                  value === 'true' ? 'Admin' : 'Staff' 
              : column.id === 'updatedAt' ? 
                  moment(value).format('MM/DD/YY hh:mm:ss A') 
              : column.id === 'email' ? (
                  <Typography className='tableLink'>{value}</Typography>
              ) : column.id === 'firstName' ? (
                  <Typography 
                      className='tableLink' 
                      

                      onClick={() =>{ dispatch(handleEditUserModal());setSelectedUserId(row._id) }}
                      
                  >{value}
                  </Typography>
              ) : 
                  value}
        </StyledTableCell>
    );
})}

                      </StyledTableRow>
                      );
                  })}
              </TableBody>
              </Table>
          </TableContainer>
          <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={filteredUsers.length} // This should be the length of the filtered array
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
/>

      </Paper>
      {isAddUserModal ? (
        <AddUserModal/>
      ): null}
        {isEditUserModal ? (
        <EditUserModal
        userId={selectedUserId}
        />
      ): null}
    </>
  )
}

export default UserInfo