import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Checkbox,
  FormGroup,
  FormControlLabel,
  DialogActions,
  Typography,
} from '@mui/material';
const DynamicFilter = ({
  open,
  onClose,
  filters,
  filterHeader,
  applyFilters,
  onApply
}) => {
  const [selectedFilters, setSelectedFilters] = useState(() => {
    // Initialize all categories to ensure none starts as undefined
    const initialFilters = {};
    filterHeader.forEach(category => {
      initialFilters[category.toLowerCase()] = new Set();
    });
    return initialFilters;
  });

  const handleToggle = (category, value) => {
    setSelectedFilters(prevFilters => {
      const updatedFilters = { ...prevFilters };
      updatedFilters[category] = new Set(updatedFilters[category]); // Make a new Set for immutability
      if (updatedFilters[category].has(value)) {
        updatedFilters[category].delete(value);
      } else {
        updatedFilters[category].add(value);
      }
      console.log('updatedFilters: ',updatedFilters)
      return updatedFilters;
    });

  };

  const handleSubmit = () => {
    onClose(); // close modal first
    onApply(selectedFilters); // now correctly references the passed prop
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Filter Projects</DialogTitle>
      <DialogContent>
        {filterHeader.map((category) => (
          <FormGroup key={category}>
            <Typography variant="subtitle1" fontWeight="bold">
              {category}
            </Typography>
            {filters[category.toLowerCase()].map((value) => (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedFilters[category] && selectedFilters[category].has(value)}
                    onChange={() => handleToggle(category, value)}
                  />
                }
                label={value}
                key={value}
              />
            ))}
          </FormGroup>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit}>Apply</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DynamicFilter;
