import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  baseUrl: `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/admin/users`,
  code: 0,
  errorMessage: '',
  storeUserToken: localStorage.getItem('token') ? true : false,
  loggedInUser: {},
  isDrawerOpen: true,
  isAddCompanyModal: false,
  isEditCompanyModal: false,
  isAddUserModal: false,
  isEditUserModal: false,
  isEditCustomerModal: false,
  isAddAdminPortalUserModal: false,
  isEditAdminPortalUserModal: false,
  isDeleteModal: false,
  lastUpdated: null,
  selectedAdmin: {}
};

export const logoutUser = createAsyncThunk(
  'logout/logout',
  async (_, { dispatch }) => {
    try {
      let userId = JSON.parse(localStorage.getItem('userData'))._id;
      const resp = await axios.put(
        `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/admin/users/logout`,
        { userId },
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          }
        }
      );

      return resp.data;
    } catch (error) {
      return error.response;
    }
  }
);

export const updateUser = createAsyncThunk(
  'user/updateuser',
  async (data, { dispatch }) => {
    try {
      let userId = JSON.parse(localStorage.getItem('userData'))._id;
      const resp = await axios.put(
        `http://${process.env.REACT_APP_BACKEND_ENDPOINT}/api/admin/update/${data.userId}`,
        { data },
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          }
        }
      );

      return resp.data;
    } catch (error) {
      return error.response;
    }
  }
);

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    loginUser: (state, action) => {
      state.loggedInUser = action.payload;
      state.storeUserToken = true;
    },
    logout: (state) => {
      state.storeUserToken = false;
      state.loggedInUser = {};
      localStorage.removeItem('token');
      localStorage.removeItem('userData');
    },
    emptyToken: (state) => {
      state.storeUserToken = false;
    },
    showError: (state, action) => {
      state.errorMessage = action.payload;
    },
    responseCode: (state, action) => {
      state.code = action.payload;
    },
    setIsDrawerOpen: (state, action) => {
      state.isDrawerOpen = action.payload;
    },
    handleAddCompanyModal: (state) => {
      state.isAddCompanyModal = !state.isAddCompanyModal;
    },
    handleEditCompanyModal: (state) => {
      state.isEditCompanyModal = !state.isEditCompanyModal;
    },
    handleLastUpdate: (state, action) => {
      state.lastUpdated = action.payload;
    },
    handleAddUserModal: (state) => {
      state.isAddUserModal = !state.isAddUserModal;
    },
    handleEditUserModal: (state) => {
      state.isEditUserModal = !state.isEditUserModal;
    },
    handleEditCustomerModal: (state) => {
      state.isEditCustomerModal = !state.isEditCustomerModal;
    },
    handleAddAdminPortalUserModal: (state) => {
      state.isAddAdminPortalUserModal = !state.isAddAdminPortalUserModal;
    },
    handleEditAdminPortalUserModal: (state, action) => {
      state.selectedAdmin = action.payload;
      state.isEditAdminPortalUserModal = !state.isEditAdminPortalUserModal;
    },
    handleDeleteModal: (state) => {
      state.isDeleteModal = !state.isDeleteModal;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(logoutUser.fulfilled, (state) => {
        state.storeUserToken = false;
        state.loggedInUser = {};
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        // Handle updateUser success
      })
      .addCase(logoutUser.rejected, (state, action) => {
        state.errorMessage = action.error.message;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.errorMessage = action.error.message;
      });
  }
});

export const {
  selectedAdmin,
  loginUser,
  handleLastUpdate,
  setError,
  logout,
  emptyToken,
  showError,
  responseCode,
  setIsDrawerOpen,
  handleAddCompanyModal,
  handleEditCompanyModal,
  handleAddUserModal,
  handleEditUserModal,
  handleEditCustomerModal,
  handleAddAdminPortalUserModal,
  handleEditAdminPortalUserModal,
  handleDeleteModal
} = loginSlice.actions;

export default loginSlice.reducer;
