import { Box, Button, Card,  Typography, TextField, FormControl, InputLabel, Select, MenuItem, FormControlLabel, Switch } from '@mui/material';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import BeardcrumNavigator from '../../components/BeardcrumNavigator';
import Sidebar from '../../components/Sidebar';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { useRef } from 'react';
import {ActivityLogBox, ActivityLogText, PostSearch, PostBox, PostSearchInput, PostSearchButton, AboutCard} from '../../components/styles/ActivityBox.styles'
import { Field, Form, Formik } from 'formik';
import {  useNavigate } from "react-router-dom";

import * as Yup from "yup";
import { addCoupon } from '../../features/coupons/couponsSlice';
import Loading from '../../components/Loading';
import ActivityLog from '../../components/ActivityLog';
const CouponCard = styled(Card)(({theme}) => ({
  background: '#F7F7F7',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '16px',
  gap: '16px'
}))
const CouponInnerBox = styled(Box)(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: '16px',
  gap: '11px',
  background: '#E8E8E8',
  boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)',
  [theme.breakpoints.down('md')] : {
    width: '100%',
  },
  [theme.breakpoints.up('md')] : {
    flex: '1'
  },
}))
const CouponButton = styled(Button)(({theme}) => ({
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
  [theme.breakpoints.up('md')]: {
    width: '345px',
  },
}))
const initialValues ={
  name: '',
  description: '',
  service: 'Windows',
  image: null,
  value: '',
  code: '',
  statusBit: true
}
const AddCoupon = () => {
const {isDrawerOpen} = useSelector((store) => store.login)
const {isLoading} = useSelector((store) => store.coupon)
const dispatch = useDispatch();
const navigate = useNavigate();
const [imgSrc, setImgSrc] = useState('/images/circle-gray.png'); // Default image

const breadcrumbs = [
    <Typography key="3" color="text.primary" style={{
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '34px',
        lineHeight: '36px',
        color: '#000000'
    }}>
        Coupons
    </Typography>
];
const [isSwitch, SetIsSwitch] = useState('enabled')
const handleSwitch = (value) => {
  if(value === 'enabled'){
    SetIsSwitch('disabled')
  }else{
    SetIsSwitch('enabled')
  }
}
let imgInput = useRef(null)
let imgRef = useRef(null)
const pickFile = () => {
    imgInput.current.click();
}
  // Function to handle file selection and update image preview
  const handlePickImage = (event, setFieldValue) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const fileReader = new FileReader();
      fileReader.onload = (e) => setImgSrc(e.target.result);
      fileReader.readAsDataURL(file);
      setFieldValue('image', file);  // Set the file to the Formik state
    } else {
      setImgSrc('');
      setFieldValue('image', null);  // Reset the image field in the Formik state
    }
  };
  
  const validationSchema = Yup.object({
    name: Yup.string().trim().required('*Please enter the name of the coupon').matches(/\S+/, 'Name field cannot be empty'),
    description: Yup.string().trim().required('*Please enter the description for the coupon').matches(/\S+/, 'Description field cannot be empty'),
    value: Yup.string().trim().required('*Please input the value for this coupon').matches(/\S+/, 'Value field cannot be empty'),
    code: Yup.string().trim().required('*Please enter the coupon code').matches(/\S+/, 'Code field cannot be empty'),
    image: Yup.mixed().required('An image is required'), 
    // other fields validation
  }); 
  
if(isLoading){
  return (
      <Loading/>
  )
}


  return (
    <div className="page-section">
      <Sidebar/>
      <Box className="page-content" sx={{width: isDrawerOpen ? `calc(100% - 240px)` : `calc(100% - 57px)`, overflow: "hidden"}}>
        <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 3
            }}>
            <BeardcrumNavigator breadcrumbs={breadcrumbs ? breadcrumbs : "Beardcrums"}/>
        </Box>

        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'start',
          flexWrap: 'wrap',
          gap: '1rem'
        }}>
          <AboutCard>
            <Formik 
                initialValues={initialValues} 
                validationSchema={validationSchema} 
                onSubmit={(values, formikHelpers) => {
                  if (!values.image) {
                    alert('Please select an image');
                    return;
                  }
                  if (!imgInput.current.files[0]) {
                   
                    values.image = '/images/circle-gray.png'
                  } else {
                    values.image = imgInput.current.files[0];
                  }
                  values.statusBit = (isSwitch === 'enabled'? true: false);
          
                  formikHelpers.resetForm();
                  dispatch(addCoupon(values)).then(() => {
                    // This code will execute after the async action is completed
                    navigate('/coupons', { state: { newCouponAdded: true } });
                }); 
                }}

              >
              {({errors, touched, isValid,setFieldValue, dirty}) => (
                <Form>
                  
                  <CouponCard>
                    <Typography sx={{display: 'block', fontSize: '34px', width: '100%'}} variant="h3" >Add Coupon</Typography>
                
                      <Box sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        alignItems: 'flex-start',
                        gap: '1rem',
                        width: '100%'
                      }}>
                        <CouponInnerBox>
                        <Field as={TextField}
                         name="name"  sx={{width: '100%'}} label="Coupon Name" 
                         error={touched.name && !!errors.name} 
                         helperText={touched.name && errors.name} />

                        <Field as={TextField} sx={{
                            width: '100%',
                            mt: 3,
                            mb: 3
                          }} name="description" label="Coupon Description" multiline rows={4} error={touched.description && !!errors.description} helperText={touched.description && errors.description} />

                        <Field  as={TextField}  sx={{width: '100%',mb: 3}} 
                          name="value" label="Coupon Value (%)" 
                          error={touched.value && !!errors.value} 
                          helperText={touched.value && errors.value} />
                          <FormControl fullWidth sx={{mb: 3}}>
                            <InputLabel id="service">Service</InputLabel>
                            <Field as={Select}
                              labelId="service"
                              id="service"
                              name="service"
                              label="Service"
                            >
                              <MenuItem value='Windows'>Windows</MenuItem>
                              <MenuItem value="Sliding Glass Doors">Sliging Glass Doors</MenuItem>
                              <MenuItem value="Doors">Interior Doors</MenuItem>
                            </Field>
                          </FormControl>
                          <Field as={FormControlLabel}
                            sx={{
                              fontSize: '16px',
                              letterSpacing: '1.25px',
                              color: '#979797'
                            }}
                            name="statusBit"
                            value={isSwitch}
                            control={<Switch color="primary" checked={isSwitch=== 'enabled'? true: false}
                            onChange={(e) => handleSwitch(e.target.value)}/>}
                            label={isSwitch=== 'enabled' ? 'Enabled' : 'Disabled'}
                            labelPlacement="start"
                          />
                     

                          <Field  as={TextField}  sx={{width: '100%'}} name="code" label="Coupon Code" error={touched.code && !!errors.code} helperText={touched.code && errors.code} />

                        
                        </CouponInnerBox>
                        <CouponInnerBox sx={{alignItems: 'center', minHeight: '482px', justifyContent: 'center'}}>

                          <Box>
                          <img src={imgSrc} alt="Upload preview" ref={imgRef} style={{ width: '100%' }} />
                          </Box>
                          <Button variant="contained" startIcon={<AddRoundedIcon />} onClick={pickFile}>
                            Upload
                          </Button>
                          <input type="file" hidden ref={imgInput} onChange={(event) => handlePickImage(event, setFieldValue)} />
                          {touched.image && errors.image && <Typography color="error">{errors.image}</Typography>}

                          <Typography sx={{fontSize: '12px'}}>
                            Recommended image size is 900x450 px <br/>
                            Image type should be .jpeg <br/>
                            Corners will be trimmed on display with 10pt radius
                          </Typography>
                        </CouponInnerBox>
                      
                      </Box>
                      <CouponButton disabled={!dirty || !isValid} type='submit' variant='contained'>Save</CouponButton>
                  </CouponCard>
                </Form>
              )}
            </Formik>
          </AboutCard>
         {/* <ActivityLog/> */}
        </Box>
      </Box>
    </div>  
  )
}

export default AddCoupon