import React, { useState, useEffect } from 'react';
import axios from 'axios';

const HealthCheck = () => {
  const [serverStatus, setServerStatus] = useState({ message: 'Checking server status...', status: null });
  useEffect(() => {
    const checkServerHealth = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/health`);
        setServerStatus({ message: response.data.message, status: response.status });
      } catch (error) {
    
        const statusCode = error.response ? error.response.status : 500;
        setServerStatus({ message: 'Server is down', status: statusCode  || 500});
      }
    };
  
    checkServerHealth();
  }, []);

  return (
    <div>
      <h2>Frontend Code: {200}</h2>
      <h2>Server Status: {serverStatus.message}</h2>
      {serverStatus.status && <h2>Status Code: {serverStatus.status}</h2>}
    </div>
  );
};

export default HealthCheck;
