import {
    Box,
    Button,
    TextField,
    Typography,
  } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "../../components/Sidebar";
import useMediaQuery from '@mui/material/useMediaQuery';
import BeardcrumNavigator from "../../components/BeardcrumNavigator";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { useEffect } from "react";
import { getVariables, updateVariable } from "../../features/system-variables/systemVariableSlice";
import { useState } from "react";
import Loading from "../../components/Loading";
import { InputAdornment } from '@mui/material';
import { CSVLink } from "react-csv";

const SystemVariables = () => {
const dispatch = useDispatch();
const {isDrawerOpen} = useSelector((store) => store.login);
const {variables, isLoading} = useSelector((store) => store.systemVariable);
const matches = useMediaQuery('(max-width:600px)');
const [isDataLoaded, setDataLoaded] = useState(false);

useEffect(() => {
    dispatch(getVariables());
    setDataLoaded(true); 
},[dispatch])


const validationSchema = Yup.object({
    reliPortion: Yup.number()
        .typeError('*Please enter a percentage')
        .required('*Please enter a percentage')
        .test('is-decimal', 'Invalid decimal input', (value) => !isNaN(value)),

    materialSurcharge: Yup.number()
        .typeError('*Please enter a percentage')
        .required('*Please enter a percentage')
        .test('is-decimal', 'Invalid decimal input', (value) => !isNaN(value)),

    windowsPermitFee: Yup.number()
        .typeError('*Please enter the permit fee')
        .required('*Please enter the permit fee')
        .test('is-decimal', 'Invalid decimal input', (value) => !isNaN(value)),

    windowsDeliveryFee: Yup.number()
        .typeError('*Please enter the delivery fee')
        .required('*Please enter the delivery fee')
        .test('is-decimal', 'Invalid decimal input', (value) => !isNaN(value)),

    windowsReliFee: Yup.number()
    .typeError('*Please enter the reli fee')
    .required('*Please enter the reli fee')
    .test('is-decimal', 'Invalid decimal input', (value) => !isNaN(value)),

    slidingGlassDoorPermitFee: Yup.number()
        .typeError('*Please enter the permit fee')
        .required('*Please enter the permit fee')
        .test('is-decimal', 'Invalid decimal input', (value) => !isNaN(value)),

    slidingGlassDoorDeliveryFee: Yup.number()
        .typeError('*Please enter the delivery fee')
        .required('*Please enter the delivery fee')
        .test('is-decimal', 'Invalid decimal input', (value) => !isNaN(value)),

    slidingGlassDoorReliFee: Yup.number()
        .typeError('*Please enter the reli fee')
        .required('*Please enter the reli fee')
        .test('is-decimal', 'Invalid decimal input', (value) => !isNaN(value)),

    interiorDoorPermitFee: Yup.number()
        .typeError('*Please enter the delivery fee')
        .required('*Please enter the delivery fee')
        .test('is-decimal', 'Invalid decimal input', (value) => !isNaN(value)),


    interiorDoorReliFee: Yup.number()
        .typeError('*Please enter the reli fee')
        .required('*Please enter the reli fee')
        .test('is-decimal', 'Invalid decimal input', (value) => !isNaN(value)),
});




const formik = useFormik({
    enableReinitialize: true,  
    initialValues: {
      reliPortion: variables.data?.reliPortion? variables.data?.reliPortion : 0.00,
      materialSurcharge: variables.data?.materialSurcharge ? variables.data?.materialSurcharge : 0.00,
      windowsPermitFee: variables.data?.windowsPermitFee ? variables.data?.windowsPermitFee : 0.00,
      windowsDeliveryFee: variables.data?.windowsDeliveryFee ? variables.data?.windowsDeliveryFee : 0.00,
      windowsReliFee: variables.data?.windowsReliFee ? variables.data?.windowsReliFee : 0.00,
      slidingGlassDoorPermitFee: variables.data?.slidingGlassDoorPermitFee ? variables.data?.slidingGlassDoorPermitFee : 0.00,
      slidingGlassDoorDeliveryFee: variables.data?.slidingGlassDoorDeliveryFee ? variables.data?.slidingGlassDoorDeliveryFee : 0.00,
      slidingGlassDoorReliFee: variables.data?.slidingGlassDoorReliFee ? variables.data?.slidingGlassDoorReliFee : 0.00,
      interiorDoorPermitFee: variables.data?.interiorDoorPermitFee ? variables.data?.interiorDoorPermitFee : 0.00,
      interiorDoorReliFee: variables.data?.interiorDoorReliFee ? variables.data?.interiorDoorReliFee : 0.00,

    },
    onSubmit: (values) => {

      dispatch(updateVariable(values)).then(()=>{
        setDataLoaded(true); 
        dispatch(getVariables());

      })
      console.log(values);
    },
    validationSchema
});
  // Prepare CSV data
  const csvData = [{
    ReliPortion: formik.values.reliPortion,
    MaterialSurcharge: formik.values.materialSurcharge,
    WindowsPermitFee: formik.values.windowsPermitFee,
    WindowsDeliveryFee: formik.values.windowsDeliveryFee,
    WindowsReliFee: formik.values.windowsReliFee,
    SlidingGlassDoorPermitFee: formik.values.slidingGlassDoorPermitFee,
    SlidingGlassDoorDeliveryFee: formik.values.slidingGlassDoorDeliveryFee,
    SlidingGlassDoorReliFee: formik.values.slidingGlassDoorReliFee,
    InteriorDoorPermitFee: formik.values.interiorDoorPermitFee,
    InteriorDoorReliFee: formik.values.interiorDoorReliFee
}];

const breadcrumbs = [
    <Typography
      key="3"
      color="text.primary"
      style={{
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "34px",
        lineHeight: "36px",
        color: "#000000",
      }}
    >
      System Variables
    </Typography>,
  ];

  if(isLoading){
    return (
        <Loading/>
    )

}
  return (
    <div className="page-section">
      <Sidebar/>
        <Box className="page-content" sx={{width: isDrawerOpen ? `calc(100% - 240px)` : `calc(100% - 57px)`, overflow: "hidden"}}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "wrap",
            gap: "1rem",
            mb: 3,
          }}
        >
          <BeardcrumNavigator
            breadcrumbs={breadcrumbs ? breadcrumbs : "Beardcrums"}
          />
          <Box
            component="div"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
            }}
          >
        <CSVLink data={csvData} filename="system_variables.csv">

            <Button
              variant="outlined"
              className="bc-btn-outline"
              color="primary"
            >
              Export csv
            </Button>
            </CSVLink>
          </Box>
        </Box>

            <Box component="div" sx={{ py: 4, px: matches? 2: 8, backgroundColor: '#F7F7F7'}}>
                <form onSubmit={formik.handleSubmit}>
                    <Box component="div" sx={{display: 'flex', gap: '4rem', flexWrap: 'wrap', width: '100%', mb: 7}}>
                    <div style={{width : matches? '100%' : 'auto'}}>
                            <TextField
                                id="materialSurcharge"
                                name="materialSurcharge"
                                label="Materials Surcharge"
                                {...formik.getFieldProps('materialSurcharge')}
                                error = {Boolean(formik.errors.materialSurcharge) && Boolean(formik.touched.materialSurcharge)}
                                helperText = {Boolean(formik.touched.materialSurcharge) && formik.errors.materialSurcharge}
                                sx={{
                                    width: matches? '100%': '400px',
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                }}
                                onInput={(e) => {
                                    e.target.value = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
                                }}
                                
                            />
                            <div className="variable-input-require">This is applied to each Product</div>
                        </div>
                        <div style={{width : matches? '100%' : 'auto'}}>
            
                        <TextField
                            id="reliPortion"
                            name="reliPortion"
                            label="Reli Portion"
                            {...formik.getFieldProps('reliPortion')}
                            error={Boolean(formik.errors.reliPortion) && Boolean(formik.touched.reliPortion)}
                            helperText={formik.touched.reliPortion && formik.errors.reliPortion}
                            sx={{ width: matches ? '100%' : '400px' }}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}
                            
                            onInput={(e) => {
                                e.target.value = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
                            }}
                        />


                            <div className="variable-input-require">This is applied to each Product</div>
                        </div>
                        
                    </Box>
                    <Typography sx={{
                        fontWeight: '600',
                        fontSize: '14px',
                        lineHeight: '16px',
                        letterSpacing: '1.25px',
                        textTransform: 'uppercase',
                        color: '#000000',
                        mb: 3
                    }}>
                        Windows Fees
                    </Typography>
                    <Box component="div" sx={{display: 'flex', gap: '4rem', flexWrap: 'wrap', width: '100%', mb: 5}}>
                    <div style={{width : matches? '100%' : 'auto'}}>
                            
                            <TextField
        id="windowsDeliveryFee"
        name="windowsDeliveryFee"
        label="Delivery Fee"
        {...formik.getFieldProps('windowsDeliveryFee')}
        error={Boolean(formik.errors.windowsDeliveryFee) && Boolean(formik.touched.windowsDeliveryFee)}
        helperText={Boolean(formik.touched.windowsDeliveryFee) && formik.errors.windowsDeliveryFee}
        sx={{ width: matches ? '100%' : '400px' }}
        InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
        onInput={(e) => {
            e.target.value = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
        }}
    />
    
                                <div className="variable-input-require">This is applied to each Window</div>
                            </div>
                        <div style={{width : matches? '100%' : 'auto'}}>
                            <TextField
                                id="windowsPermitFee"
                                name="windowsPermitFee"
                                label="Permit Fee"
                                {...formik.getFieldProps('windowsPermitFee')}
                                error = {Boolean(formik.errors.windowsPermitFee) && Boolean(formik.touched.windowsPermitFee)}
                                helperText = {Boolean(formik.touched.windowsPermitFee) && formik.errors.windowsPermitFee}
                                sx={{
                                    width: matches? '100%': '400px',
                                }}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                }}
                                onInput={(e) => {
                                    e.target.value = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
                                }}
                                
                            />
                            <div className="variable-input-require">This is applied to each Window</div>
                        </div>
                        <div style={{width : matches? '100%' : 'auto'}}>
                            <TextField
                                id="windowsReliFee"
                                name="windowsReliFee"
                                label="Reli Fee"
                                {...formik.getFieldProps('windowsReliFee')}
                                error = {Boolean(formik.errors.windowsReliFee) && Boolean(formik.touched.windowsReliFee)}
                                helperText = {Boolean(formik.touched.windowsReliFee) && formik.errors.windowsReliFee}
                                sx={{
                                    width: matches? '100%': '400px',
                                }}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                }}
                                onInput={(e) => {
                                    e.target.value = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
                                }}
                                
                            />
                            <div className="variable-input-require">This is applied to each Window</div>
                        </div>
                    </Box>
                    <Typography sx={{
                        fontWeight: '600',
                        fontSize: '14px',
                        lineHeight: '16px',
                        letterSpacing: '1.25px',
                        textTransform: 'uppercase',
                        color: '#000000',
                        mb: 3
                    }}>
                        Sliding Glass Door Fees
                    </Typography>
                    <Box component="div" sx={{display: 'flex', gap: '4rem', flexWrap: 'wrap', width: '100%', mb: 5}}>
                    <div style={{width : matches? '100%' : 'auto'}}>
                            <TextField
                                id="slidingGlassDoorDeliveryFee"
                                name="slidingGlassDoorDeliveryFee"
                                label="Delivery Fee"
                                {...formik.getFieldProps('slidingGlassDoorDeliveryFee')}
                                error = {Boolean(formik.errors.slidingGlassDoorDeliveryFee) && Boolean(formik.touched.slidingGlassDoorDeliveryFee)}
                                helperText = {Boolean(formik.touched.slidingGlassDoorDeliveryFee) && formik.errors.slidingGlassDoorDeliveryFee}
                                sx={{
                                    width: matches? '100%': '400px',
                                }}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                }}
                                onInput={(e) => {
                                    e.target.value = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
                                }}
                                
                            />
                            <div className="variable-input-require">This is applied to each Sliding Glass Door</div>
                        </div>
                        <div style={{width : matches? '100%' : 'auto'}}>
                            <TextField
                                id="slidingGlassDoorPermitFee"
                                name="slidingGlassDoorPermitFee"
                                label="Permit Fee"
                                {...formik.getFieldProps('slidingGlassDoorPermitFee')}
                                error = {Boolean(formik.errors.slidingGlassDoorPermitFee) && Boolean(formik.touched.slidingGlassDoorPermitFee)}
                                helperText = {Boolean(formik.touched.slidingGlassDoorPermitFee) && formik.errors.slidingGlassDoorPermitFee}
                                sx={{
                                    width: matches? '100%': '400px',
                                }}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                }}
                                onInput={(e) => {
                                    e.target.value = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
                                }}
                                
                            />
                            <div className="variable-input-require">This is applied to each Sliding Glass Door</div>
                        </div>
                        <div style={{width : matches? '100%' : 'auto'}}>
                            <TextField
                                id="slidingGlassDoorReliFee"
                                name="slidingGlassDoorReliFee"
                                label="Reli Fee"
                                {...formik.getFieldProps('slidingGlassDoorReliFee')}
                                error = {Boolean(formik.errors.slidingGlassDoorReliFee) && Boolean(formik.touched.slidingGlassDoorReliFee)}
                                helperText = {Boolean(formik.touched.slidingGlassDoorReliFee) && formik.errors.slidingGlassDoorReliFee}
                                sx={{
                                    width: matches? '100%': '400px',
                                }}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                }}
                                onInput={(e) => {
                                    e.target.value = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
                                }}
                                
                            />
                            <div className="variable-input-require">This is applied to each Sliding Glass Door</div>
                        </div>
                    </Box>
                    <Typography sx={{
                        fontWeight: '600',
                        fontSize: '14px',
                        lineHeight: '16px',
                        letterSpacing: '1.25px',
                        textTransform: 'uppercase',
                        color: '#000000',
                        mb: 3
                    }}>
                        Interior Doors Fees
                    </Typography>
                    <Box component="div" sx={{display: 'flex', gap: '4rem', flexWrap: 'wrap', justifyContent: matches?'end': 'start', width: '100%', mb: 5}}>
                        <div style={{width : matches? '100%' : 'auto'}}>
                            <TextField
                                id="interiorDoorPermitFee"
                                name="interiorDoorPermitFee"
                                label="Delivery Fee"
                                {...formik.getFieldProps('interiorDoorPermitFee')}
                                error = {Boolean(formik.errors.interiorDoorPermitFee) && Boolean(formik.touched.interiorDoorPermitFee)}
                                helperText = {Boolean(formik.touched.interiorDoorPermitFee) && formik.errors.interiorDoorPermitFee}
                                sx={{
                                    width: matches? '100%': '400px',
                                }}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                }}
                                onInput={(e) => {
                                    e.target.value = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
                                }}
                                
                            />
                            <div className="variable-input-require">This is applied to each Interior Door</div>
                        </div>
                        <div style={{width : matches? '100%' : 'auto'}}>
                            <TextField
                                id="interiorDoorReliFee"
                                name="interiorDoorReliFee"
                                label="Reli Fee"
                                {...formik.getFieldProps('interiorDoorReliFee')}
                                error = {Boolean(formik.errors.interiorDoorReliFee) && Boolean(formik.touched.interiorDoorReliFee)}
                                helperText = {Boolean(formik.touched.interiorDoorReliFee) && formik.errors.interiorDoorReliFee}
                                sx={{
                                    width: matches? '100%': '400px',
                                }}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                }}
                                onInput={(e) => {
                                    e.target.value = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
                                }}
                                
                            />
                            <div className="variable-input-require">This is applied to each Interior Door</div>
                        </div>
                    </Box>
                    <Box sx={{display: 'flex', justifyContent: 'end'}}>
                        <Button type="submit" variant="contained" disabled={!formik.dirty || !formik.isValid}>save</Button>
                    </Box>
                </form>
            </Box>
        </Box>
    </div>
  )
}

export default SystemVariables