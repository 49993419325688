import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const url = "https://course-api.com/react-useReducer-cart-project";

const initialState = {
  name: "",
  list: {},
  detail:{}
};
export const getCoupons = createAsyncThunk(
  "coupon/getCoupons",
  async (thunkAPI) => {
    try {
   
      const resp = await axios(
        `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/admin/coupons/listing`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      // console.log(resp);
      return resp.data;
    } catch (error) {
      // console.log(error.response);
      return thunkAPI.rejectWithValue("something went wrong");
    }
  }
);
export const addCoupon = createAsyncThunk(
  "coupon/addCoupon",
  async (values, thunkAPI) => {
    let formData = new FormData();
    formData.append("name", values.name);
    formData.append("description", values.description);
    formData.append("code", values.code);
    formData.append("value", values.value);
    formData.append("image", values.image);
    formData.append("service", values.service);
    formData.append("statusBit", values.statusBit);

    try {
      const imageUpload = await axios.post(
        `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/admin/coupons/uploadimage`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      const uploadedImageUrl = imageUpload.data.data.Location;
      
      // Create a new FormData for the second request
      formData = new FormData();
      formData.append("name", values.name);
      formData.append("description", values.description);
      formData.append("code", values.code);
      formData.append("value", values.value);
      formData.append("service", values.service);
      formData.append("statusBit", values.statusBit);
      formData.append("image", uploadedImageUrl);

      await axios.post(
        `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/admin/coupons/add`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      
    } catch (error) {
      console.log('ERR addCoupon: ', error.response);
      return thunkAPI.rejectWithValue('something went wrong');
    }
  }
);

export const getCouponDetail = createAsyncThunk(
  "coupon/getCouponDetail",
  async (id, thunkAPI) => {
    try {
      console.log('get cooupon detail : ',id)
      const resp = await axios(
        `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/admin/coupons/detail/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      // console.log(resp);
      return resp.data;
    } catch (error) {
      //   console.log(error.response);
      return thunkAPI.rejectWithValue("something went wrong");
    }
  }
);
export const updateCoupon = createAsyncThunk(
  "coupon/updateCoupon",
  async (values, thunkAPI) => {
    try {
      let uploadedImageUrl = values.image; // Assume it's a URL by default
      console.log('uploadedImageUrl: ',uploadedImageUrl)
      // Check if 'values.image' is a File object (indicating a new image to upload)
      if (values.image instanceof File) {
        console.log('cmg upload pic?')
        // Prepare the formData for the image upload
        const imageFormData = new FormData();
        imageFormData.append("image", values.image);

        // Perform the image upload
        const imageUploadResponse = await axios.post(
          `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/admin/coupons/uploadimage`,
          imageFormData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        // Extract the URL from the upload response
        uploadedImageUrl = imageUploadResponse.data.data.Location;
      }
      console.log('uploadedImageUrl: ',uploadedImageUrl)
      // Now prepare formData for the coupon update
      const formData = new FormData();
      formData.append("name", values.name);
      formData.append("description", values.description);
      formData.append("code", values.couponCode); // Assuming 'couponCode' is the correct field name
      formData.append("value", values.couponValue); // Assuming 'couponValue' is the correct field name
      formData.append("service", values.service);
      formData.append("statusBit", values.statusBit);
      formData.append("image", uploadedImageUrl); // Use the uploaded image URL or the original URL

      // Perform the coupon update
      await axios.post(
        `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/admin/coupons/update/${values.id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

    } catch (error) {
      console.error('ERR updateCoupon: ', error.response || error.message);
      return thunkAPI.rejectWithValue('something went wrong during coupon update');
    }
  }
);


const couponSlice = createSlice({
  name: "coupon",
  initialState,
  reducers: {
    clearCart: (state) => {
      state.cartItems = [];
    },
  },
  extraReducers: {
    [getCoupons.pending]: (state) => {
      state.isLoading = true;
    },
    [getCoupons.fulfilled]: (state, action) => {
      console.log(action);
      state.isLoading = false;
      state.list = action.payload;
    },
    [getCoupons.rejected]: (state) => {
      state.isLoading = false;
    },
    [getCouponDetail.pending]: (state) => {
      state.isLoading = true;
    },
    [getCouponDetail.fulfilled]: (state, action) => {
      console.log(action);
      state.isLoading = false;
      state.detail = action.payload;
    },
    [getCouponDetail.rejected]: (state) => {
      state.isLoading = false;
    },
    
    [addCoupon.pending]: (state) => {
      state.isLoading = true;
    },
    [addCoupon.fulfilled]: (state, action) => {
      console.log(action);
      state.isLoading = false;
      // state.list = action.payload;
    },
    [addCoupon.rejected]: (state) => {
      state.isLoading = false;
    },
    [updateCoupon.pending]: (state) => {
      state.isLoading = true;
    },
    [updateCoupon.fulfilled]: (state, action) => {
      console.log(action);
      state.isLoading = false;
      state.detail = action.payload;
    },
    [updateCoupon.rejected]: (state) => {
      state.isLoading = false;
    },
    
  },
});

export const { clearCart } = couponSlice.actions;

export default couponSlice.reducer;