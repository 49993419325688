import { Box, Table, TableContainer, Typography, IconButton, Button, ButtonGroup } from '@mui/material';
import React from 'react'
import Paper from '@mui/material/Paper';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import SearchBox from '../../components/SearchBox';
import { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import TableLink from '../../components/TableLink';
import { useState } from 'react';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTransactions } from '../../features/transactions/transactionSlice';
import Loading from '../../components/Loading';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import moment from "moment/moment";
import FilterListRoundedIcon from "@mui/icons-material/FilterListRounded";
import FilterListIcon from '@mui/icons-material/FilterList';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import DynamicFilter from "../projects/DynamicFilter";

import NorthIcon from '@mui/icons-material/North';

const checkNonArrayValues = (obj) => {
  // Get the values of the object
  const values = Object.values(obj);
  
  // Check if any of the values are not arrays
  const hasNonArrayValues = values.some(value => !Array.isArray(value));
  
  return hasNonArrayValues;
};

const columns = [

  { id: "_id", label: "Product ID", minWidth: 100, fontWeight: "600" },
  { id: "name", label: "Customer", minWidth: 100, fontWeight: "600" },
  { id: "serviceType", label: "Service Type", minWidth: 150, fontWeight: "600" },
  {
    id: "transactionType",
    label: "Payment Type",
    minWidth: 150,
    fontWeight: "600",
  },
  { id: "scheduled", label: "Scheduled", minWidth: 100, fontWeight: "600" },
  { id: "completedDate", label: "Completed", minWidth: 100, fontWeight: "600" },
  { id: "orderStatus", label: "Project Status", minWidth: 100, fontWeight: "600" },
  { id: "paidByCustomer", label: "Paid by Customer", minWidth: 160, fontWeight: "600" },
  { id: "paidToContractor", label: "Paid to Contractor", minWidth: 160, fontWeight: "600" },
  { id: "dueToContractor", label: "Due to Contractor", minWidth: 160, fontWeight: "600" },

];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.gray,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
const SortButton = styled(Button)(({ theme }) => ({
  // Add your styles here to match the button in the image
  margin: theme.spacing(2),
  padding: theme.spacing(1),
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
    backgroundColor: '#ddd',
  },
}));

const Transaction = () => {
  const dispatch = useDispatch();
  const { isLoading, transactions } = useSelector((store) => store.transaction);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({});

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchValue, setSearchValue] = useState('');
  const [sortedTransactions, setSortedTransactions] = useState([]);
  const [sortDirection, setSortDirection] = useState('desc');


  
  const requestSort = (key) => {
    let direction = 'ascending';
    if (
      sortConfig.key === key &&
      sortConfig.direction === 'ascending'
    ) {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const applyFilters = (transactionsData,newFilters) => {

    console.log("Applying filters with newFilters:", newFilters);

    // Normalize filter keys
    const normalizedFilters = {};
    for (const [key, values] of Object.entries(newFilters)) {
        let normalizedKey = key.toLowerCase();
        normalizedFilters[normalizedKey] = values;
    }
    console.log("Normalized Filters:", normalizedFilters);
    // using case insensitive search to avoid casing mismatch issues
    const allObjKeysMap = Object.keys(transactionsData?.[0]).map((e)=> {return {[e.toLowerCase()]:e}}).reduce((acc, obj) => ({ ...acc, ...obj }), {});

    console.log(Object.keys(allObjKeysMap))
    return transactionsData?.filter(transaction => {
        const searchMatch = Object.values(transaction).some(value =>
            String(value).toLowerCase().includes(searchValue.toLowerCase())
        );


        if (!searchMatch) return false;

        return Object.entries(normalizedFilters).every(([key, values]) => {
            if (!values.size) return true;

            // if (key === 'name') {
            //     // Assuming we want to check both firstName and lastName
            //     const fullName = (transaction.firstName + ' ' + transaction.lastName).toLowerCase();
            //     return Array.from(values).some(filterValue => fullName.includes(filterValue.toLowerCase()));
            // }
            // using case insensitive search to avoid casing mismatch issues
            if (!Object.keys(allObjKeysMap).includes(key)) {
                console.log(`transaction is missing the key ${key}`);
                return false;
            }

            return Array.from(values).some(filterValue =>
                String(transaction[allObjKeysMap[key]]).toLowerCase().includes(filterValue.toLowerCase())
            );
        });
    });
};


  const handleApplyFilters = (newFilters) => {
    console.log("New Filters Applied:", newFilters,transactions.data);
    setSelectedFilters(newFilters);
    const filteredTransactions = applyFilters(transactions.data,newFilters);
    console.log("Filtered Transactions:", filteredTransactions);
    setSortedTransactions(filteredTransactions);  
  };

  const applySorting = (data) => {
    console.log(data)
    let sortedData=data
    if (sortConfig.key !== null) {
       sortedData = [...data].sort((a, b) => {
        const dateA = new Date(a?.[sortConfig.key]);
        const dateB = new Date(b?.[sortConfig.key]);
        return sortConfig.direction === 'ascending' ? dateA - dateB : dateB - dateA;
      });
  
      // sortedData?.sort((a, b) => {
      //   if (a[sortConfig.key] < b[sortConfig.key]) {
      //     return sortConfig.direction === 'ascending' ? -1 : 1;
      //   }
      //   if (a[sortConfig.key] > b[sortConfig.key]) {
      //     return sortConfig.direction === 'ascending' ? 1 : -1;
      //   }
      //   return 0;
      // });
    }
    return sortedData;
  };
  const sortLastLogin = () => {
    const sortedData = [...sortedTransactions].sort((a, b) => {
      const dateA = new Date(a.updatedAt);
      const dateB = new Date(b.updatedAt);
      return sortDirection === 'asc' ? dateA - dateB : dateB - dateA;
    });

    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    setSortedTransactions(sortedData);
  };

  useEffect(() => {
    dispatch(getTransactions());
  }, [dispatch]);

  useEffect(() => {
    if (transactions?.data) {
      setSortedTransactions(transactions.data);
      
    }
  }, [transactions?.data]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSearch = (searchedValue) => {
    setSearchValue(searchedValue);
  };

if(isLoading){
  return (
    <Loading/>
  )
}

return (
  <>
    <Box component="div" sx={{
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      alignItems: 'center',
      mb: 3,
    }}>
      <Box component="div">
        <Box sx={{
          background: '#FFFFFF',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
          borderRadius: '33px',
          height: 50,
          display: 'flex',
          alignItems: 'center',
          maxWidth: '245px',
          border: '1px solid #ddd',
          overflow: 'hidden'
        }}>
          <SearchRoundedIcon sx={{
            width: '16%',
            marginLeft: '6px'
          }}/>
          <input type="text" value={searchValue} placeholder='Search' className='search-input' onChange={(e) => handleSearch(e.target.value.toLowerCase())} />
        </Box>
      </Box>

      <Box
              component='div'
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '1rem',
              }}
            >
              <Button
                variant='outlined'
                className='bc-btn-outline'
                color='primary'
                onClick={() => setFilterModalOpen(true)}
              >
                Filter
              </Button>
            </Box>
      
      {/* <SortButton
        startIcon={(<IconButton aria-label="filter-icon">
          <FilterListIcon />
        </IconButton>)}
        onClick={sortLastLogin}
      >
        
      </SortButton> */}
    </Box>
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <StyledTableRow>
              {columns.map((column) => (
                <StyledTableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth, fontWeight: column.fontWeight }}
                >
                   <Box
                  sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                  onClick={() => {column.id == "completedDate" && requestSort(column.id)}}
                >
                  {column.label}
                  {column.id == "completedDate" &&  ( 
                    <Box component="span" sx={{ display: 'inline-flex', alignItems: 'center', marginLeft: '5px' }}>
                      {sortConfig.key === column.id
                        ? (sortConfig.direction === 'ascending' ? <NorthIcon fontSize="small"/> : <ArrowDownwardIcon fontSize="small"/>)
                        : <ArrowDownwardIcon fontSize="small"/> 
                      }
                    </Box>
                  )}
                  </Box>
                </StyledTableCell>
              ))}
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {applySorting(sortedTransactions)
              .filter((data) => data.name.toLowerCase().includes(searchValue))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <StyledTableRow hover role="checkbox" tabIndex={-1} key={row._id}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <StyledTableCell key={column.id} align={column.align}>
                          {/* Implement custom rendering logic here based on column.id */}
                          {value}
                        </StyledTableCell>
                      );
                    })}
                  </StyledTableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={sortedTransactions.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>

    <DynamicFilter
      open={filterModalOpen}
        onClose={() => setFilterModalOpen(false)}
        filters={{
          name:[...new Set(
            transactions?.data?.map((transaction) => transaction.name )
          )],
          transactiontype:[
            ...new Set(
              transactions?.data?.map((transaction) => transaction.transactionType)
            ),
          ],
          orderstatus:[
            ...new Set(
              transactions?.data?.map((transaction) => transaction.orderStatus)
            ),
          ]
        }}
        filterHeader={["Name", "transactionType", "orderStatus"]}
        applyFilters={applyFilters}
        onApply={handleApplyFilters}  // Make sure this function is defined and passed here
      />

    

  </>
);
}

export default Transaction