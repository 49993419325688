import {
  Box,
  Table,
  TableContainer,
  Typography,
  IconButton,
  Button,
} from "@mui/material";
import React, { useState } from "react";
import BeardcrumNavigator from "../../components/BeardcrumNavigator";
import Sidebar from "../../components/Sidebar";
import Paper from "@mui/material/Paper";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

import { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";

import { useDispatch, useSelector } from "react-redux";
import TableLink from "../../components/TableLink";
import AddCompany from "./AddCompany";

import { handleAddCompanyModal } from "../../features/login/loginSlice";
import { useEffect } from "react";
import {
  addCompany,
  getCompanies,
} from "../../features/companies/companySlice";
import { CSVLink } from "react-csv";
import Loading from "../../components/Loading";
import Alert from "@mui/material/Alert";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { companyResponseClr } from "../../features/companies/companySlice";
import CloseIcon from "@mui/icons-material/Close";
import FilterListIcon from "@mui/icons-material/FilterList";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

import NorthIcon from "@mui/icons-material/North";
import { Link, useLocation } from "react-router-dom";

const columns = [
  { id: "companyName", label: "Company", minWidth: 100, fontWeight: "600" },
  {
    id: "representativeName",
    label: "Representative",
    minWidth: 100,
    fontWeight: "600",
  },
  {
    id: "representativeNumber",
    label: "Representative Number",
    minWidth: 100,
    fontWeight: "600",
  },
  {
    id: "representativeEmail",
    label: "Representative Email",
    minWidth: 170,
    fontWeight: "600",
  },
  {
    id: "numberOfUsers",
    label: "Number of Users",
    minWidth: 150,
    fontWeight: "600",
  },
  {
    id: "numberOfOpenProjects",
    label: "Projects Open",
    minWidth: 100,
    fontWeight: "600",
  },
  {
    id: "numberOfCompletedProjects",
    label: "Projects Completed",
    minWidth: 100,
    fontWeight: "600",
  },
  {
    id: "dateLastActive",
    label: "Date Last Active",
    minWidth: 150,
    fontWeight: "600",
  },
  {
    id: "companyStatus",
    label: "Status",
    fontWeight: "600",
    textTransform: "capitalize",
    minWidth: 100,
  },
  // { id: "actions", label: "Actions", fontWeight: "600", minWidth: 150 },
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.gray,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
    backgroundColor: "#ddd",
  },
}));

const Companies = () => {
  const { isDrawerOpen, isAddCompanyModal } = useSelector(
    (store) => store.login
  );
  const { list, isLoading, alert, isUpdated, responseStatus, responseMsg } =
    useSelector((store) => store.company);
  const dispatch = useDispatch();
  const location = useLocation();

  const [alertDialog, setAlertDialog] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState("");
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });
  const requestSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };
  const [filters, setFilters] = useState({
    companyName: "",
    representativeName: "",
    representativeNumber: "",
    representativeEmail: "",
    numberOfUsers: "",
    numberOfOpenProjects: "",
    numberOfCompletedProjects: "",
    dateLastActive: "",
    companyStatus: "",
  });

  const applyFilters = (data) => {
    return data.filter((row) => {
      // Check if row matches the search term in any column
      const searchMatch = Object.values(row).some((value) =>
        String(value).toLowerCase().includes(searchValue.toLowerCase())
      );

      // Proceed with other filters if searchMatch is true
      return (
        searchMatch &&
        Object.entries(filters).every(([key, filterValue]) => {
          if (!filterValue) return true; // Skip if filter is empty
          const rowValue = String(row[key]).toLowerCase();
          return rowValue.includes(filterValue.toLowerCase());
        })
      );
    });
  };

  const applyFiltersAndSorting = (data) => {
    let filteredData = applyFilters(data);
    if (sortConfig.key !== null) {
      filteredData = filteredData.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return filteredData;
  };

  useEffect(() => {
    dispatch(getCompanies());
  }, [dispatch, isAddCompanyModal]);

  // useEffect(() => {
  //   dispatch(getCompanies());
  // } ,[addCompany]);

  const breadcrumbs = [
    <Typography
      key="3"
      color="text.primary"
      style={{
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "34px",
        lineHeight: "36px",
        color: "#000000",
      }}
    >
      Companies
    </Typography>,
  ];
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSearch = (searchedValue) => {
    setSearchValue(searchedValue);
    setPage(0);
  };
  const [sortDirection, setSortDirection] = useState("desc"); // 'asc' for ascending, 'desc' for descending
  const [sortedCompanies, setSortedCompanies] = useState([]);
  console.log("sortDirection :", sortDirection);
  const filteredCompanies = sortedCompanies.filter(
    (data) =>
      data.companyName?.toLowerCase().includes(searchValue) ||
      data.representativeName?.toLowerCase().includes(searchValue) ||
      data.representativeNumber?.toLowerCase().includes(searchValue) ||
      data.representativeEmail?.toLowerCase().includes(searchValue) ||
      data.numberOfUsers?.toString().includes(searchValue) || // Convert number to string
      data.numberOfOpenProjects?.toString().includes(searchValue) || // Convert number to string
      data.numberOfCompletedProjects?.toString().includes(searchValue) || // Convert number to string
      data.dateLastActive?.toLowerCase().includes(searchValue) ||
      data.companyStatus?.toLowerCase().includes(searchValue)
  );

  // useEffect(() => {
  //   dispatch(getCompanies());
  // }, [dispatch, location]);

  function renderTableCell(column, value, row) {
    switch (column.id) {
      case "companyName":
        return <TableLink text={value} route={row.id} />;
      case "representativeNumber":
        return (
          <Typography>
            <TableLink route={row.id} text={value} />
          </Typography>
        );
      case "representativeEmail":
        return (
          <Typography>
            <TableLink route={row.id} text={value} />
          </Typography>
        );
      case "dateLastActive":
        if (isNaN(Date.parse(value))) {
          return "";
        }
        const formattedDate = new Intl.DateTimeFormat("en-US", {
          year: "2-digit",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: true,
          timeZoneName: "short",
        }).format(new Date(value));
        return formattedDate;
      case "companyStatus":
        return value === "enable"
          ? "Enabled"
          : value === "disable"
          ? "Disabled"
          : value;
      default:
        return column.format && typeof value === "number"
          ? column.format(value)
          : value;
    }
  }
  const SortButton = styled(Button)(({ theme }) => ({
    margin: theme.spacing(2),
    padding: theme.spacing(1),
  }));

  const sortLastLogin = () => {
    const sortedData = [...list?.data].sort((a, b) => {
      const dateA = new Date(a?.dateLastActive);
      const dateB = new Date(b?.dateLastActive);
      return sortDirection === "asc" ? dateB - dateA : dateA - dateB;
    });

    setSortDirection(sortDirection === "asc" ? "desc" : "asc"); // Toggle sort direction
    setSortedCompanies(sortedData);
  };

  useEffect(() => {
    // Only update sortedCompanies if it's not already set and Companies.data is available
    if (list?.data) {
      console.log("companies fetching");
      setSortedCompanies(list.data);
    }
  }, [list?.data, dispatch]);

  if (isLoading || !sortedCompanies) {
    return <Loading />;
  }

  return (
    <div className="page-section">
      <Sidebar />
      <Box
        className="page-content"
        sx={{
          width: isDrawerOpen ? `calc(100% - 240px)` : `calc(100% - 57px)`,
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "wrap",
            gap: "1rem",
            mb: 3,
          }}
        >
          <BeardcrumNavigator
            breadcrumbs={breadcrumbs ? breadcrumbs : "Beardcrums"}
          />
          <Box
            component="div"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <CSVLink data={list.data ? list.data : "No data available yet"}>
              <Button
                variant="outlined"
                className="bc-btn-outline"
                color="primary"
              >
                Export csv
              </Button>
            </CSVLink>
            <Button
              variant="outlined"
              className="bc-btn-outline"
              color="primary"
              onClick={() => dispatch(handleAddCompanyModal())}
            >
              Add Company
            </Button>
          </Box>
        </Box>

        {alert ? (
          <Alert
            severity={responseStatus}
            color={responseStatus}
            sx={{ mb: 3, width: "100%" }}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  dispatch(companyResponseClr(false));
                  setAlertDialog(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {responseMsg}
          </Alert>
        ) : null}

        <Box
          component="div"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            alignItems: "center",
            mb: 3,
          }}
        >
          <Box component="div">
            <Box
              sx={{
                background: "#FFFFFF",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                borderRadius: "33px",
                height: 50,
                display: "flex",
                alignItems: "center",
                maxWidth: "245px",
                border: "1px solid #ddd",
                overflow: "hidden",
              }}
            >
              <SearchRoundedIcon
                sx={{
                  width: "16%",
                  marginLeft: "6px",
                }}
              />
              <input
                type="text"
                value={searchValue}
                // placeholder="Search"
                className="search-input"
                onChange={(e) => handleSearch(e.target.value.toLowerCase())}
              />
            </Box>
          </Box>
          <SortButton
            startIcon={<FilterListIcon />}
            onClick={sortLastLogin}
          ></SortButton>
        </Box>
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table" sx={{}}>
              <TableHead>
                <StyledTableRow>
                  {columns.map((column) => (
                    <StyledTableCell key={column.id}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                        onClick={() => requestSort(column.id)}
                      >
                        {column.label}
                        {column.id !== "actions" && (
                          <Box
                            component="span"
                            sx={{
                              display: "inline-flex",
                              alignItems: "center",
                              marginLeft: "5px",
                            }}
                          >
                            {sortConfig.key === column.id ? (
                              sortConfig.direction === "ascending" ? (
                                <NorthIcon fontSize="small" />
                              ) : (
                                <ArrowDownwardIcon fontSize="small" />
                              )
                            ) : (
                              <ArrowDownwardIcon fontSize="small" />
                            )}
                          </Box>
                        )}
                      </Box>
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              </TableHead>

              <TableBody>
                {applyFiltersAndSorting(sortedCompanies)
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    console.log("came here");
                    return (
                      <StyledTableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={parseInt(Date.now() * Math.random())}
                      >
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <StyledTableCell
                              key={column.id}
                              align={column.align}
                              sx={{ textTransform: column.textTransform }}
                            >
                              {renderTableCell(column, value, row)}
                            </StyledTableCell>
                          );
                        })}
                      </StyledTableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={filteredCompanies.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
      {isAddCompanyModal ? <AddCompany /> : null}
    </div>
  );
};

export default Companies;
