import React from 'react';
import { useParams } from 'react-router-dom';
import ServiceProducts from './ServiceProducts';
import ColumnConfigurations from './ColumnConfigurations';

const ServiceProductsContainer = () => {
  const { serviceid } = useParams();
  const columns = ColumnConfigurations[serviceid] || [];

  return <ServiceProducts columns={columns} />;
};

export default ServiceProductsContainer;
