import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom'
import Header from './Header'

const Layout = () => {
  const { storeUserToken } = useSelector((store) => store.login);

  if (!storeUserToken) {
    return <Navigate to="/login" replace />;
  }

  return (
    <>
      {/* <Header /> */}
      <Outlet />
    </>
  );
}

export default Layout;
