import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { singleCompanyDetail } from "../features/companies/companySlice";

const TableLink = (props) => {
  const { text, route } = props;

  // console.log('props: ', props);
  return (
    <Link to={route} className="tableLink">
      {text}
    </Link>
  );
};

export default TableLink;
