import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { loginUser, logout } from './features/login/loginSlice';

const useRehydrateLoginState = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const rehydrateLoginState = () => {
      const token = localStorage.getItem('token');
      const storedUserData = localStorage.getItem('userData');
      if (token && storedUserData) {
        const userData = JSON.parse(storedUserData);
        dispatch(loginUser({
          data: {
            ...userData,
            token
          }
        }));
      } else {
        dispatch(logout());
        // navigate('/login');
      }
    };

    rehydrateLoginState();
  }, [dispatch, navigate]);
};

export default useRehydrateLoginState;
