// utils.js
export const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };
  
  export const extractNumbers = (inputString) => {
    const sanitizedString = inputString.replace(/[^0-9.]/g, '');
    const parts = sanitizedString.split('.');
    if (parts.length > 2) {
      return parts[0] + '.' + parts.slice(1).join('');
    }
    return sanitizedString;
  };
  
  export const formatCurrency = (value) => {
    if (!value) return '$0.00';
    const numberValue = Number(value);
    if (isNaN(numberValue)) return '$0.00';
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(numberValue);
  };
  