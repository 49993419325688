import {
  Box,
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import axios from 'axios';
import { responseCode, showError } from "../features/login/loginSlice";
import AlertMessage from "../components/AlertMessage";


const DeleteUser = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { baseUrl, code } = useSelector((store) => store.login);
  const [isError, setIsError] = useState(false);
  const [ifSuccess, setIfSuccess] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: localStorage.getItem('email') ? localStorage.getItem('email') : "",
      password: "",
    },
    onSubmit: async (values) => {
      try {
        if (!values.password) {
          dispatch(responseCode(400));
          dispatch(showError('*Please enter your password'));
        } else {
          let payload = {
            email: values.email,
            password: values.password,
          };
          try {
            let response = await axios.post(`${baseUrl}/deleteUser`, payload);
            setIfSuccess(true);
            dispatch(responseCode(response.data.code));
            dispatch(showError(response.data.message));
            setTimeout(() => {
              setIfSuccess(false);
              navigate('/');
            }, 300);
          } catch (error) {
            console.error(error);
            setIsError(true);
            dispatch(responseCode(error.response.data.code));
            dispatch(showError(error.response.data.message));
          }
        }
      } catch (error) {
        console.log(error);
        setIsError(true);
        dispatch(responseCode(error.response.data.code));
        dispatch(showError(error.response.data.message));
      }
    },
    validate: (values) => {
      let errors = {};
      if (!values.password) {
        errors.password = "";
        dispatch(responseCode(400));
        dispatch(showError('*Please enter your password'));
      }
      if (values.password.length < 8) {
        errors.password = '';
        dispatch(responseCode(400));
        dispatch(showError('*Password must be at least 8 characters'));
      }
      return errors;
    },
  });

  return (
    <>
      {/* <Header /> */}
      <Box
        sx={{
          minHeight: "80vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box sx={{ width: "400px", textAlign: "center", p: "20px" }}>
          <form onSubmit={formik.handleSubmit}>
            <Typography
              variant="h4"
              component="h2"
              sx={{ mb: "2rem" }}
            >
              Delete User
            </Typography>
            {code === 200 && ifSuccess === true ? (
              <AlertMessage color="success" />
            ) : (null)}
            {code === 400 ? (
              <AlertMessage color="error" />
            ) : null}
            <FormControl
              sx={{ mb: 5, width: "100%" }}
              variant="outlined"
            >
              <InputLabel htmlFor="outlined-adornment-email">Email</InputLabel>
              <OutlinedInput
                type="email"
                name="email"
                id="outlined-adornment-email"
                onChange={formik.handleChange}
                value={formik.values.email}
                label="Email"
              />
            </FormControl>
            <FormControl
              sx={{ mb: 5, width: "100%" }}
              variant="outlined"
            >
              <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
              <OutlinedInput
                type="password"
                name="password"
                id="outlined-adornment-password"
                onChange={formik.handleChange}
                value={formik.values.password}
                startAdornment={
                  <InputAdornment position="start">
                    <LockOutlinedIcon />
                  </InputAdornment>
                }
                label="Password"
              />
              {formik.errors.password ? (
                <Box sx={{ color: "red", pt: 1 }}>{formik.errors.password}</Box>
              ) : (
                ""
              )}
            </FormControl>
            <Button
              type='submit'
              sx={{ width: "100%", minHeight: "36px", mb: "30px" }}
              variant="contained"
              color="primary"
              disabled={formik.values.password.length < 8}
            >
              DELETE USER
            </Button>
          </form>
        </Box>
      </Box>
    </>
  );
};

export default DeleteUser;
