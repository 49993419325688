import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';


const initialState = {
    services: {},
    serviceDetail: {},
    isLoading: false,
    alert: false,
    responseStatus: '',
    responseMsg: '',
};

export const getServices = createAsyncThunk(
    'services/getServices',
    async (thunkAPI) => {
      try {
        const resp = await axios(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/admin/service/listing`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          }
        });
        // console.log(resp);
        return resp.data;
      } catch (error) {
        // console.log(error.response);
        return thunkAPI.rejectWithValue('something went wrong');
      }
    }
  );

  export const serviceProductList = createAsyncThunk(
    "service/serviceProductList",
    async (id, thunkAPI) => {
      try {
        const resp = await axios.get(
          `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/admin/csv/getProducts/${id}`,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        // console.log(resp);
        return resp.data;
      } catch (error) {
        console.log(error.response);
        // return "something went wrong";
        return error.response;
      }
    }
  );

  export const uploadProductServiceCSV = createAsyncThunk('service/uploadProductServiceCSV', async(values, thunkAPI) => {
    try {

      const resp = await axios.post(
        `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/admin/csv/upload-csv`, 
        values, 
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      return resp.data;
    } catch (error) {
      return error.response;
    }
  })
  
 

  export const updatePriceColumn = createAsyncThunk(
    "service/updatePriceColumn",
    async (values, thunkAPI) => {
      console.log('updatePriceColumn values :' ,values )
      let keyLeft;
      if (values.currentPath.split('/')[2] == '64332da1beedb46ff5a0fcaf'){
        keyLeft = 'rsPrice';
      } else {
        keyLeft = 'ppui';
      }
      try {
        const requestBody = {
          [keyLeft]: (values['ppui']), 
          serviceType: values.currentPath.split('/')[2],
        };
        console.log('requestBody" ',requestBody)
        const resp = await axios.post(
          `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/admin/csv/update/${values.id}`,
          requestBody,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        // console.log(resp);
        return resp.data;
      } catch (error) {
        console.log(error.response);
        // return "something went wrong";
        return error.response;
      }
    }
  );
  




  const serviceSlice = createSlice({
    name: 'services',
    initialState,
    reducers: {
      clearCart: (state) => {
        state.cartItems = [];
      },
      serviceResponseClr: (state, action) => {
        state.responseMsg = "";
        state.responseStatus = "";
        state.alert = false;
      },
    },
    extraReducers: {
      [getServices.pending]: (state) => {
        state.isLoading = true;
      },
      [getServices.fulfilled]: (state, action) => {
        state.isLoading = false;
        state.services = action.payload;
      },
      [getServices.rejected]: (state) => {
        state.isLoading = false;
      },
      [serviceProductList.pending]: (state) => {
        state.isLoading = true;
      },
      [serviceProductList.fulfilled]: (state, action) => {
        state.isLoading = false;
        console.log(action.payload);
        state.serviceDetail = action.payload;
      },
      [serviceProductList.rejected]: (state) => {
        state.isLoading = false;
      },
      [uploadProductServiceCSV.pending]: (state) => {
        state.isLoading = true;
      },
      [uploadProductServiceCSV.fulfilled]: (state, action) => {
        if(action.payload.message){
          state.responseStatus = "success";
        }else{
          state.responseStatus = "error";
        }
        state.responseMsg = action.payload.message ? action.payload.message : action.payload.data.message;
        state.alert = true;
        state.isLoading = false;
      },
      [uploadProductServiceCSV.rejected]: (state) => {
        state.isLoading = false;
      },
      [updatePriceColumn.pending]: (state) => {
        state.isLoading = true;
      },
      [updatePriceColumn.fulfilled]: (state, action) => {
        if(action.payload.message){
          state.responseStatus = "success";
        }else{
          state.responseStatus = "error";
        }
        state.responseMsg = action.payload.message ? action.payload.message : action.payload.data.message;
        state.alert = true;
        state.isLoading = false;
      },
      [updatePriceColumn.rejected]: (state) => {
        state.isLoading = false;
      },
    }
  });
  
  
  export const { clearCart, serviceResponseClr } = serviceSlice.actions;
  
  export default serviceSlice.reducer;