import React from 'react';
import { StyledTableCell, StyledTableRow } from './StyledTableComponents';
const ServiceTableHeader = ({ columns }) => {
    console.log(
        columns
    )
  return (
    
    <StyledTableRow>
      {columns?.map(column => (
        <StyledTableCell key={column.id} style={{ minWidth: column.minWidth }}>
          <b>{column.label}</b>
        </StyledTableCell>
      ))}
    </StyledTableRow>
  );
};

export default ServiceTableHeader;
