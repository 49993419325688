import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch, useSelector } from 'react-redux';
import { Container, FormControl, InputLabel, MenuItem, Select, TextField, Typography, Box,  IconButton, Button } from '@mui/material';
import { handleEditUserModal } from '../../features/login/loginSlice';
import ModeEditOutlineRoundedIcon from '@mui/icons-material/ModeEditOutlineRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useRef } from 'react';
import { useState } from 'react';
import { Field, Form, Formik } from 'formik';

import * as Yup from "yup";
import { editStaff, getUsers,staffDetail } from '../../features/userInfo/userInfoSlice';
import {  useParams } from 'react-router-dom';
import { useEffect } from 'react';

const EditUserModal = ({userId}) => {

const {isEditUserModal} = useSelector((store) => store.login)
const {companyDetail} = useSelector((store) => store.company)
const { staff } = useSelector((store) => store.userInfo);
const dispatch = useDispatch();
const param = useParams();

const [status, setStatus] = useState((staff?.data?.statusBit))
const [isImageChanged, setIsImageChanged] = useState(false); 
const [isImageRemoved, setIsImageRemoved] = useState(false);

const [accountType, setAccountType] = useState(staff?.data?.accountType === 'true');
console.log('staff.data ',staff.data)
useEffect(()=>{
    dispatch(staffDetail(userId));
}, [dispatch, userId]);
const [initialValues, setInitialValues] = useState({
    firstName: '',
    email: '',
    phoneNumber: '',
    approvedByReli: 'active',
    status: false, // initial status value
    accountType: 'Standard', // set as 'Standard' or 'Admin'
    image:''
});
useEffect(() => {
    if (staff?.data) {
        setStatus(staff.data.statusBit);
        setAccountType(staff.data.accountType === 'true' ? 'Admin' : 'Standard');
        setInitialValues({
            name: staff.data.firstName || '',
            email: staff.data.email || '',
            phone: staff.data.phoneNumber || '',
            approvedByReli: staff.data.approvedByReli || '',
            status: staff.data.statusBit,
            accountType: staff.data.accountType === 'true' ? 'Admin' : 'Standard',
            image:staff.data.image
        });
    }
}, [staff, dispatch]);

let imgInput = useRef(null)
let imgRef = useRef(null)
const formikRef = useRef(); // Ref for accessing Formik instance
const isCustomDirty = () => {
    if (!formikRef.current) {
        return false;
    }
    const formValues = formikRef.current.values;
    const initialFormValues = initialValues;

    // Adjusting for correct comparison
    const formValuesForComparison = {
        ...formValues,
        accountType: formValues.accountType === 'Admin',
    };
    const initialFormValuesForComparison = {
        ...initialFormValues,
        accountType: initialFormValues.accountType === 'Admin',
    };

    return JSON.stringify(formValuesForComparison) !== JSON.stringify(initialFormValuesForComparison);
};

const pickFile = () => {
    imgInput.current.click();
}
const handleAddImg = (e) => {
    let file = e.target.files[0];
    let url = URL.createObjectURL(file);
    imgRef.current.src = url;
    URL.revokeObjectURL(file);
    setIsImageChanged(true); 
    setIsImageRemoved(false); // Reset image removal state
}
const clearImgRef = () => {
    imgInput.current.value = '';
    imgRef.current.src = '/images/circle-gray.png';
    setIsImageChanged(false); // Reset image changed state
    setIsImageRemoved(true); // Indicate that the image was removed
}

  return (
    <>
    <Dialog
    open={isEditUserModal}
    scroll='body'
    onClose={() => dispatch(handleEditUserModal())}
    aria-describedby="scroll-dialog-description"
  >
    <DialogTitle id="scroll-dialog-title" sx={{ 
        p: 1,
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '24px',
        lineHeight: '24px',
        letterSpacing: '0.18px',
        color: '#000000'
     }}>Company User</DialogTitle>
     <Box component="div" sx={{ 
        display: 'flex',
        justifyContent: 'center', 
        flexDirection: 'column',
        alignItems: 'center'
    }}>
        <Box component="div" className='addImg'>
            <img 

            src={staff?.data?.image ? staff?.data?.image : '"/images/circle-gray.png"'}
            alt="addCompanyImg" ref={imgRef} style={{width: '100%', height: '100%', objectFit: 'cover'}}/>
            <input type="file" hidden id="getImg" ref={imgInput} onChange={(e) => handleAddImg(e)}/>
        </Box>
        <Box component="div">
            <IconButton onClick={pickFile}>
                <ModeEditOutlineRoundedIcon/>
            </IconButton>
            <IconButton onClick={clearImgRef}>
                <CloseRoundedIcon/>
            </IconButton>
        </Box>
        <Typography sx={{
            fontStyle: 'normal',
            fontWeight: '600',
            fontSize: '18px',
            lineHeight: '20px',
            letterSpacing: '0.4px',
            color: '#000000'
        }}>
            Company Name: {companyDetail.data?.findCompany.companyName} 
        </Typography>
    </Box>
    <Formik
        innerRef={formikRef}

        initialValues={initialValues} 
        enableReinitialize={true}
        onSubmit={(values) => {
            const submissionValues = {
                ...values,
                company: param.companyid,
                status,
                accountType: values.accountType === 'Admin', // Convert back to boolean for submission
                image: imgInput.current.files[0] ? imgInput.current.files[0] : null,
                id: userId
            };
            if (isImageChanged && imgInput.current.files[0]) {
                submissionValues.image = imgInput.current.files[0];
            } else if (isImageRemoved) {
                submissionValues.image = null;
            } else {
                // If no image change or removal has been made, keep the original image if any
                submissionValues.image = submissionValues.image || null;
            }
            dispatch(editStaff(submissionValues));
            dispatch(handleEditUserModal());
            dispatch(getUsers(param.companyid));
        }}
        validationSchema= {Yup.object().shape({
            name: Yup.string()
                .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field")
                .required('Please enter the contractor name'),
            
            email: Yup.string()
                .email('Please enter a valid email address')
                .required('Please enter an email address'),
            
            phone: Yup.string()
                .matches(/^[0-9]{10}$/, "Please enter a valid 10-digit phone number")
                .required('Please enter a phone number'),
        })}
        
    >
        {({errors, touched, isValid, dirty,setFieldValue }) => (
            <Form>
                <DialogContent
                    sx={{
                        p: 0    
                    }}
                >
                <DialogContentText 
                    component={'div'}
                    id="scroll-dialog-description"
                    tabIndex={-1}
                >
                    <Container maxWidth='xs'>
                        <Field as={TextField} 
                            sx={{width: '100%', mb: 4, mt: 5}}
                            id="firstName"
                            name="name"
                            label="Name"
                            variant="outlined" 
                            error = {Boolean(errors.firstName) && Boolean(touched.firstName)}
                            helperText = {Boolean(touched.firstName) && errors.firstName}
                        />
                        <Field as={TextField}  
                            sx={{width: '100%', mb: 4}}
                            id="email"
                            name="email"
                            label="Email"
                            variant="outlined" 
                            error = {Boolean(errors.email) && Boolean(touched.email)}
                            helperText = {Boolean(touched.email) && errors.email}
                            disabled={true}
                        />
                        <Field as={TextField}  
                            sx={{width: '100%', mb: 4}}
                            id="phone"
                            name="phone"
                            label="Phone"
                            variant="outlined" 
                            error = {Boolean(errors.phone) && Boolean(touched.phone)}
                            helperText = {Boolean(touched.phone) && errors.phone}
                        />
                        <FormControl fullWidth sx={{ mb: 4 }}>
                            <InputLabel id="approvedByReli">Approved by Reli</InputLabel>
                            <Field as={Select}
                                labelId="approvedByReli"
                                id="approvedByReli"
                                name="approvedByReli"
                                label="Approved by Reli"
                                error = {Boolean(errors.approvedByReli) && Boolean(touched.approvedByReli)}
                                helperText = {Boolean(touched.approvedByReli) && errors.approvedByReli}
                            >
                                <MenuItem value='accepted' selected={true}>Accepted</MenuItem>
                                <MenuItem value='pending'>Pending</MenuItem>
                                <MenuItem value='rejected'>Rejected</MenuItem>
                            </Field>
                        </FormControl>
                      
                        <div className="group">
    <p className="btn_label">Status</p>
    <div className="btn_group">
        <button
            type="button"
            className={status ? 'active' : ''}
            onClick={() => {
                if (!status) { // Change only if status is not already true
                    const newStatus = !status;
                    setStatus(newStatus);
                    setFieldValue('status', newStatus);
                }
            }}
        >
            Enabled
        </button>
        <button
            type="button"
            className={!status ? 'active' : ''}
            onClick={() => {
                if (status) { // Change only if status is not already false
                    const newStatus = !status;
                    setStatus(newStatus);
                    setFieldValue('status', newStatus);
                }
            }}
        >
            Disabled
        </button>
    </div>
                        </div>
                        <div className="group">
                            <p className="btn_label">Account Type</p>
                            <div className="btn_group">
                            <button
                            type="button"
                            className={accountType === 'Standard' ? 'active' : ''}
                            onClick={() => {
                                setAccountType('Standard');
                                setFieldValue('accountType', 'Standard');
                            }}
                        >
                            Standard
                        </button>
                        <button
                            type="button"
                            className={accountType === 'Admin' ? 'active' : ''}
                            onClick={() => {
                                setAccountType('Admin');
                                setFieldValue('accountType', 'Admin');
                            }}
                        >
                            Admin
                        </button>

                            </div>
                        </div>

                    </Container>
                
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button variant='outlined' onClick={() => dispatch(handleEditUserModal())}>Cancel</Button>
                <Button disabled={!isCustomDirty() && (!dirty && !isImageChanged && !isImageRemoved) } type='submit' variant='contained'>Save</Button>
                </DialogActions>
            </Form>
        )}
    </Formik>
  </Dialog>
    </>
  )
}

export default EditUserModal