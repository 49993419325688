// initial state
const initialState = {
    user: {}
}

const authUserReducer = (state = initialState, action) =>{
    console.log(state, action);
    if(action.type === "LOGIN_USER"){
        return { user: action.payload}
    }
    if(action.type === "USER_FORGOT"){
        return { user: action.payload}
    }
    if(action.type === "USER_FORGOT"){
        return { user: action.payload}
    }
    if(action.type === "USER_SET_PASSWORD"){
        return { user: action.payload}
    }
    return {...state}
}


export default authUserReducer